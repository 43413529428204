import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import axios from "utils/axios";

function Quiz() {
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(true);

  const [quizValues, setQuizValues] = useState([]);
  function handleChange(e, q) {
    const newValue = {
      id: q.id,
      question: q.question,
      answer: e.target.value,
      label: q.label,
      type:q.type,
    };
    setQuizValues([...quizValues, newValue]);
  }
  //   console.log(quiz);
  useEffect(() => {
    try {
      fetch("http://localhost:3010/quiz")
        .then((response) => response.json())
        .then((data) => setQuiz(data), setLoading(false));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);
  if (loading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Stack spacing={2} maxWidth={800} margin="auto">
      <Typography variant="h4">
        Elevate your IELTS writing skills with writingchex IELTS Quizes
      </Typography>
      {/* <form onSubmit={() => console.log(quizValues)}> */}
      <Stack spacing={3}>
        {quiz.map((q) => (
          <MainCard key={q.id}>
            <Typography variant="h5" marginBottom={1}>
              {q.label}
            </Typography>
            <Typography variant="subtitle1" color="GrayText" marginBottom={1}>
              {q.question}
            </Typography>
            {q.type == "text" && (
              <TextField fullWidth onBlur={(e) => handleChange(e, q)} />
            )}
            {q.type == "textarea" && (
              <TextField
                onBlur={(e) => handleChange(e, q)}
                multiline
                rows={8}
                fullWidth
                placeholder="type your answer here..."
              />
            )}
            {q.type == "checkbox" && (
              <FormGroup onChange={(e) => console.log(e.target.value)}>
                <Grid container justifyContent="space-between">
                  {q.options.map((option) => (
                    <Grid item xs={3}>
                      <FormControlLabel
                        onChange={(e) => handleChange(e, q)}
                        control={<Checkbox />}
                        label={option.title}
                        value={option.value}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            )}
            {q.type == "radio" && (
              <FormGroup>
                <RadioGroup onChange={(e) => handleChange(e, q)}>
                  <Grid container justifyContent="space-between">
                    {q.options.map((option) => (
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={<Radio />}
                          value={option.value}
                          label={option.title}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </FormGroup>
            )}
          </MainCard>
        ))}
      </Stack>
      <MainCard>
        <Stack direction="row" justifyContent="space-between">
          <Button
            size="large"
            variant="contained"
            onClick={() => console.log(quizValues)}
          >
            Submit
          </Button>
          <Button size="large" variant="outlined" color="error">
            Quit
          </Button>
        </Stack>
      </MainCard>
      {/* </form> */}
    </Stack>
  );
}

export default Quiz;
