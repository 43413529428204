// material-ui
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "../showMore.css";
// project-imports
import MainCard from "components/MainCard";
import Avatar from "components/@extended/Avatar";
// assets
import { Clock, DocumentText1 } from "iconsax-react";
import moment from "moment";
import ShowMore from "react-show-more";
import { useTheme } from "@mui/material/styles";
import { FaFilePdf } from "react-icons/fa6";
import { Link } from "react-router-dom";
import axios from "utils/axios";
import { useState } from "react";


const ReportHeaderData = ({
  exam,
  writingResult,
  selectedTask,
  setSelectedTask,
  examWritings,
  print = false,
}) => {
  const [pdfLoading,setPdfLoading] = useState(false)
  const handleChange = (event, newValue) => {
    setSelectedTask(newValue);
  };
  const theme = useTheme();
  function downloadPdfReport(id) {
    setPdfLoading(true)
    axios({
      method: 'get',
      url: `https://admin.writingchex.com/api/v1/download/${id}`,
      headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('serviceToken'),
          'Content-Type': 'application/json'
      },
      responseType: 'blob'
  }).then(function (response) {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `report.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      setPdfLoading(false)
  });
  }
  return (
    <>
      <Stack sx={{ marginBottom: "1rem" }}>
        <Tabs
          value={selectedTask}
          centered
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          {examWritings?.map((writing) => {
            return (
              <Tab
                value={Number(writing?.task_type)}
                label={`Task ${writing?.task_type} Report`}
                style={{
                  margin: "0 0.5rem",
                  minWidth: 0,
                  minHeight: 36,
                  padding: "8px 16px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  color: theme.palette.text.primary,
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  ...(Number(writing?.task_type) === selectedTask
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }
                    : {}),
                }}
              />
            );
          })}
        </Tabs>
      </Stack>
      <MainCard
        title={
          <List disablePadding sx={{ display: "flex", alignItems: "center" }}>
            <ListItem sx={{ p: 0 }}>
              <ListItemAvatar>
                <Avatar color="error" variant="rounded">
                  <DocumentText1 variant="bold" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ my: 0 }}
                primary={
                  <Typography>Task {writingResult?.task_type}</Typography>
                }
                secondary={
                  <Typography variant="subtitle1">
                    IELTS {exam?.type?.toUpperCase()}
                  </Typography>
                }
              />
            </ListItem>
            {!print && (
            pdfLoading ? <CircularProgress/> :               <IconButton
            LinkComponent={Link}
            onClick={() => downloadPdfReport(writingResult?.id)}
          >
            <FaFilePdf />
          </IconButton>
            )}
          </List>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Task Description</Typography>
            <Typography
              dangerouslySetInnerHTML={{
                __html: writingResult?.task_description,
              }}
              sx={{ wordWrap: "break-word" }}
            ></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Task Answer</Typography>
            {!print && (
              <ShowMore lines={1} anchorClass="showMore">
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: writingResult?.task_answer,
                  }}
                  sx={{ wordWrap: "break-word" }}
                ></Typography>
              </ShowMore>
            )}
            {print && (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: writingResult?.task_answer,
                }}
                sx={{ wordWrap: "break-word" }}
              ></Typography>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default ReportHeaderData;
