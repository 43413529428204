import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FaPauseCircle } from "react-icons/fa";
import { FaCirclePlay } from "react-icons/fa6";
const Timer = ({ initialTime,setTaskDuration,isPaused,setIsPaused }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);


  useEffect(() => {
    let timer;
    if (!isPaused) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
        setTaskDuration(prev=>prev+1)
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isPaused]);

  const handleTimerAction = () => {
    setIsPaused((prev) => !prev);
  };

  const formatTime = (time) => {
    const absTime = Math.abs(time);
    const minutes = Math.floor(absTime / 60);
    const seconds = absTime % 60;
    return `${time < 0 ? "-" : ""}${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
  };
  console.log(timeLeft);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <h2
          style={{
            color: timeLeft < 60 ? "red" : "green",
            margin: 0,
            padding: 0,
          }}
        >
          {formatTime(timeLeft)}
        </h2>
        <span
          onClick={handleTimerAction}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          {isPaused ? (
            <FaCirclePlay fontSize={20} className="timer-action-button" />
          ) : (
            <FaPauseCircle fontSize={20} className="timer-action-button" />
          )}
        </span>
      </div>
      {timeLeft <= 0 && (
        <h4 style={{ margin: 0, padding: 0, color: "red" }}>Times up!</h4>
      )}
    </div>
  );
};

export default Timer;
