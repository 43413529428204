const currencySymbols = {
  AUD: "A$",
  CAD: "C$",
  EUR: "€",
  GBP: "£",
  RUB: "₽",
  USD: "$",
  CHF: "₣",
  KWD:"د.ک"
};

export function getCurrencySymbol(currencyCode) {
  return currencySymbols[currencyCode.toUpperCase()] || null;
}
