import { useRoutes } from "react-router-dom";

// project-imports
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import Print from "pages/result/Print";
import EmailConfirmation from "pages/auth/auth1/EmailConfirmation";

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    LoginRoutes,
    MainRoutes,
    {
      path:'print',
      element:<Print/>
    },
    {
      path:'email-confirmation',
      element:<EmailConfirmation/>
    }
  ]);
}
