import {
  CircularProgress,
  Grid,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import useGetWritings from "Api/hooks/useGetWritings";
import ImprovementCard from "components/Improvements/ImprovementCard";
import NoActiveImprovements from "components/Improvements/NoActiveImprovements";
import React from "react";
function Improvments() {
  const { writings, writingLoading ,refetch} = useGetWritings();
  const components = [
    { id: 1,improvements:writings?.map((task=> task.improvements.filter((task)=>task.component == 'task achievement'))), value: "Task achievement" },
    { id: 2,improvements:writings?.map((task=> task.improvements.filter((task)=>task.component == 'lexical resource'))), value: "Lexical Resource" },
    { id: 3,improvements:writings?.map((task=> task.improvements.filter((task)=>task.component == 'grammatical range & accuracy'))), value: "grammatical range & accuracy" },
    { id: 4,improvements:writings?.map((task=> task.improvements.filter((task)=>task.component == 'coherence & cohesion'))), value: "Coherence & Cohesion" },
  ];
  console.log(writings)
  if (writingLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Stack>
      <ListItemText
        primary={
          <Typography variant="h4" sx={{ marginBottom: "0.5rem" }}>
            Personalized Study Plan based on Exam Analysis
          </Typography>
        }
        secondary={
          <Typography variant="subtitle2">
            Here we provide yoimporvements:writings?.Improvments.filter(())u a comprehensive personalized study plan tailored
            to your individual strengths and weaknesses, based on in-depth
            analysis of your practice exam performance. Our detailed report
            provides targeted suggestions and actionable to-dos to help you
            improve your IELTS exam score effectively.
          </Typography>
        }
      />
      {writings.length == 0 && <NoActiveImprovements />}
      <Grid container spacing={1} marginTop={2}>
        {writings.map((writing, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ImprovementCard writing={writing} components={components} refetch={refetch}/>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
}

export default Improvments;
