import React from "react";
import MainCard from "../MainCard";
import {
  Alert,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  ArrowCircleUp,
  InfoCircle,
  MinusCirlce,
  TickCircle,
} from "iconsax-react";
import Avatar from "../@extended/Avatar";
import EcommerceRadial from "./ScoreChart";
const reportPerformance = {
  task_achievement:
    "Task Achievement measures the test taker's ability to address task requirements with relevant, accurate, and complete responses.",
  coherence:
    "Coherence and cohesion assess the organization and linking of ideas, including logical sequencing and the effective use of cohesive devices.",
  lexical:
    "Lexical Resource: evaluates the range and accuracy of the test taker's vocabulary and their ability to use words appropriately in context.",
  grammatical:
    "Grammatical Range and Accuracy examine the variety and accuracy of the test taker's grammar usage in constructing sentences.",
};
const reportDetails = [
  { value: "_strengths", label: "Strengths" },
  { value: "_issues", label: "Key Issues" },
  { value: "_suggestions", label: "Suggestions" },
];
function ReportSection({ result_key, writing, title ,print = false}) {
  const color = writing?.result[result_key] > 4 ? "#4ade80" : "#f43f5e";
  const isMobile = useMediaQuery("(max-width:767px)");
  const smallerScreen = useMediaQuery("(max-width:350px)");
  return (
    <MainCard title={title} titleVariant="h4">
      <Alert color="secondary" icon={<InfoCircle />}>
        {reportPerformance[result_key]}
      </Alert>
      <Grid
        container
        justifyContent="space-between"
        sx={{ marginTop: "0.5rem" }}
        spacing={2}
      >
        <Grid item xs={12} s={12} sm={8} md={8} order={isMobile ? 2 : 1}>
          <ListItemText
            primary={<Typography variant="subtitle1">Preformance:</Typography>}
            secondary={
              <Stack>
                <Typography variant="subtitle1">
                  {writing?.result[result_key + "_description"]}
                </Typography>
              </Stack>
            }
          />
        </Grid>
        <Grid
          item
          s={12}
          xs={12}
          sm={4}
          md={4}
          alignItems="center"
          order={isMobile ? 1 : 2}
        >
          {/* <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              alignItems: "center",
              position:'relative',
            }}
          >
            <EcommerceRadial
              key={writing?.result?.overall_band_score}
              chartHeight={smallerScreen ? 100 : !isMobile ? 170 :150}
              chartWidth={290}
              color={color}
              value={writing?.result[result_key]?.toFixed(1)}
              chartFontSize="30px"
            />
            <Typography sx={{ textAlign: "center" ,position:'absolute',bottom:'-5px'}} variant="caption" >
              {title}
              <br />
              The candidate scored {writing?.result[result_key].toFixed(1)} out
              of 9.0
            </Typography>
          </Stack> */}
          <Stack
            direction="row"
            alignItems="center"
            // spacing={2}
            // sx={{ px: 2 }}
            justifyContent="center"
            sx={{ maxHeight: "100px" }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                alignItems: "center",
                ml: !isMobile && "2rem",
                position: "relative",
              }}
            >
              <Stack sx={{position:'relative',bottom:'10px'}}>
              <EcommerceRadial
                key={writing?.result?.overall_band_score}
                chartHeight={smallerScreen ? 100 : !isMobile ? 170 : 150}
                chartWidth={290}
                color={color}
                value={writing?.result[result_key]?.toFixed(1)}
                chartFontSize="30px"
              />
              </Stack>
              <Typography
                sx={{
                  textAlign: "center",
                  position: "absolute",
                  bottom:'-3px',
                  zIndex:1000000000,
                  fontSize:'10px'
                }}
                variant="caption"
              >
                {title}
                <br />
                The candidate scored {writing?.result[result_key].toFixed(
                  1
                )}
               &nbsp; out of 9.0
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {reportDetails.map((detail, index) => {
        return (
          <>
          <Stack sx={{marginBottom:'0.3rem',marginTop:index != reportDetails?.length + 1 ? '0.3rem' : ""}}>
            <Typography
              variant="subtitle1"
            >
              {detail?.label}
            </Typography>
            <Stack
              f
              direction={{ xs: "column", md: "row" }}
              alignItems="start"
              gap="1rem"
            >
              {writing.result[result_key + detail.value].map((result) => {
                return (
                  <ListItem sx={{ p: 0, maxWidth: 500 }}>
                    <ListItemText
                      primary={
                        <Stack direction="row" alignItems="center" gap="0.5rem">
                          <Avatar
                            color={
                              detail.value == "_strengths"
                                ? "success"
                                : detail.value == "_issues"
                                ? "error"
                                : "info"
                            }
                            variant="rounded"
                            sx={{ width: "25px", height: "25px" }}
                          >
                            {detail.value == "_strengths" && <TickCircle />}
                            {detail.value == "_issues" && <MinusCirlce />}
                            {detail.value == "_suggestions" && (
                              <ArrowCircleUp />
                            )}
                          </Avatar>
                          <Typography variant="subtitle1" fontSize={13}>
                            {result.title}
                          </Typography>
                        </Stack>
                      }
                      secondary={
                        <Stack sx={{marginLeft:'2.1rem'}}>
                          <Typography variant="caption">
                            {result?.description}
                          </Typography>
                          <Typography variant="subtitle1">
                            <i>Example</i>
                          </Typography>
                          <Typography variant="caption">
                            <i>{result?.example}</i>
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                );
              })}
            </Stack>
          </Stack>
          {index != reportDetails?.length - 1 &&           <Divider/>}
          </>
        );
      })}
    </MainCard>
  );
}

export default ReportSection;
