// third-party

// assets
import { User } from "iconsax-react";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { IoPricetagsSharp } from "react-icons/io5"; // icons
import { RiDraftLine } from "react-icons/ri";
import { MdOutlineDashboard } from "react-icons/md";
import { IoStatsChartOutline } from "react-icons/io5";
import { GrLineChart } from "react-icons/gr";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { MdOutlineSpellcheck } from "react-icons/md";
import { MdOutlineQuiz } from "react-icons/md";
const icons = {
  report: IoStatsChartOutline,
  order: LiaFileInvoiceDollarSolid,
  pricing: IoPricetagsSharp,
  exam: HiOutlineClipboardDocumentList,
  draft: RiDraftLine,
  login: User,
  dashboard: MdOutlineDashboard,
  imporvments: GrLineChart,
  spelling: MdOutlineSpellcheck,
  quiz: MdOutlineQuiz,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //
export const guest = {
  id: "Panel",
  title: "Panel",
  type: "group",
  children: [
    {
      id: "pricing",
      title: "Pricing",
      type: "item",
      url: "/pricing",
      icon: icons.pricing,
    },
    {
      id: "login",
      title: "Login",
      type: "item",
      url: "/login",
      icon: icons.login,
    },
  ],
};
const support = {
  id: "Panel",
  title: "welcome",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.dashboard,
    },
    // {
    //   id:"quiz",
    //   title:"IELTS Quiz",
    //   type:"item",
    //   url:"/quiz",
    //   icon:icons.quiz
    // },
    {
      id: "Take-an-exam",
      title: "Take an exam",
      type: "item",
      url: "/exam",
      icon: icons.exam,
    },
    {
      id: "improvements",
      title: "Improvements",
      type: "item",
      url: "/improvements",
      icon: icons.imporvments,
    },
    {
      id: "spelling-practice",
      title: "Spelling Practice",
      type: "item",
      url: "/spelling",
      icon: icons.spelling,
    },
    {
      id: "my-reports",
      title: "My Reports",
      type: "item",
      url: "/my-reports",
      icon: icons.report,
    },
    {
      id: "Drafts",
      title: "Drafts",
      url: "/drafts",
      type: "item",
      icon: icons.draft,
    },
    {
      id: "orders",
      title: "Orders",
      type: "item",
      url: "/orders",
      icon: icons.order,
    },
    {
      id: "pricing",
      title: "Pricing",
      type: "item",
      url: "/pricing",
      icon: icons.pricing,
    },
  ],
};

export default support;
