import { useQuery } from "@tanstack/react-query";
import { editedExamApi } from "Api/providers/writingProviders";

export default function useGetEditedExam(examId){
    const {data:editedExam,isLoading:isEditedExamLoading} = useQuery({
        queryKey:['edited-exam'],
        queryFn:()=>editedExamApi(examId),
        enabled:!!examId
    })
    return {editedExam,isEditedExamLoading}
}