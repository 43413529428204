
// project-imports
import Routes from "routes";
import ThemeCustomization from "themes";

import Loader from "components/Loader";
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";


// auth-provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import useUser from "Api/hooks/useUser";
import AppContextProvider from "contexts/AppContext";

import "./pages/main.css"
const App = () => {
  const {user,isLoading} = useUser()
  if (isLoading) return <Loader/>
  return (
    <ThemeCustomization>
      <AppContextProvider>
      <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
        </ScrollTop>
      </AppContextProvider>
    </ThemeCustomization>
  );
};

export default App;
