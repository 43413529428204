import { useMutation } from "@tanstack/react-query";
import { postTaskApi } from "Api/providers/taskProvider";
import { useNavigate } from "react-router";

export function usePostWriting() {
const navigate = useNavigate()
  const {
    mutateAsync: postWriting,
    isPending: isPosting,
    error,
  } = useMutation({
    mutationFn: postTaskApi,
    onSuccess: (data) => {
         navigate('/payment?examId=' + data.id)
    },
  });
  return { postWriting, isPosting };
}
