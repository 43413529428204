// src/components/Breadcrumbs.js

import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => {
  return {
    breadcrumbs: {
      margin: "16px 0",
      padding: "8px 16px",
      borderRadius: "4px",
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };
});

const Breadcrumbs = () => {
    const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  if (pathnames.length === 1 && pathnames[0] == "exam") return;
  return (
    <MUIBreadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
      <Link component={RouterLink} to="/" className={classes.link} color={theme.palette.secondary.main}>
        Home
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography className={classes.text} key={to} color={theme.palette.secondary.dark}>
            {value == 'scoring' ? 'Score my writing' :value.charAt(0).toUpperCase() + value.slice(1) }
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            to={to}
            className={classes.link}
            key={to}
            color={theme.palette.secondary.main}
          >
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
