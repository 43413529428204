// material-ui
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  useMediaQuery,
} from "@mui/material";

// project-imports
import Profile from "./Profile";
import Notification from "./Notification";

import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";
import { MenuOrientation } from "config";
import useUser from "Api/hooks/useUser";
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { cword } from "utils/cword";
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n, menuOrientation } = useConfig();
  const { user, isLoading } = useUser();
  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { isLoggedIn } = useAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}

      <Box sx={{ width: "100%" }} />
      {isLoading && <CircularProgress />}
      {!isLoading && isLoggedIn && (
        <>
          <Chip
            label={user?.user?.credit == undefined ? "loading" : cword(user?.user?.credit)}
            color="success"
            variant="light"
            sx={{ margin: "0 12px" }}
          />
          <Notification />
          <Profile />
        </>
      )}
      <>
        {!isLoggedIn && (
          <Stack direction="row" spacing={2}>
            <Button component={Link} to="/register" variant="outlined">
              Signup
            </Button>
            <Button component={Link} to="/login" variant="contained" style={{color:'black'}}>
              Login
            </Button>
          </Stack>
        )}
      </>
    </>
  );
};

export default HeaderContent;
