import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { formatTime } from "utils/formatTime";

function StopWatch({ time, setTime, isRunning }) {
  const intervalRef = useRef(null);
  if (!isRunning) {
    clearInterval(intervalRef.current);
  }
  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);
    }
  }, [isRunning]);


  return (
    <Typography variant="h3" color="error">
      {formatTime(time)}
    </Typography>
  );
}

export default StopWatch;
