import {
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  Collapse,
} from "@mui/material";
import MainCard from "components/MainCard";
import Avatar from "components/@extended/Avatar";
import { ArrowDown2, InfoCircle, MinusCirlce, TickCircle } from "iconsax-react";
import { Link } from "react-router-dom";
import "../../pages/main.css";
import { useEffect, useState } from "react";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { useApp } from "contexts/AppContext";
import { ThemeMode } from "config";
import useConfig from "hooks/useConfig";
import { LiaCheckCircle } from "react-icons/lia";
import AuthDivider from "components/AuthDivider";
import useGetWritings from "Api/hooks/useGetWritings";
import { motion } from "framer-motion";

const ImprovementTodo = ({ todo, complete, setActiveCount, refetch }) => {
  const [open, setOpen] = useState(false);
  const [completed, setCompleted] = useState(todo?.done || false);
  const { refreshApp } = useApp();
  const { mode } = useConfig();

  function completeTodo(id) {
    setActiveCount((prev) => prev - 1);
    complete(id, {
      onSuccess: () => {
        enqueueSnackbar("Study task has been completed", {
          variant: "success",
          autoHideDuration: 2000,
        });
        refreshApp();
        refetch();
      },
    });
    setCompleted(true); // Update the local state
    setOpen(false);
  }

  useEffect(() => {
    setCompleted(todo?.done || false);
  }, [todo]);

  return (
    <MainCard sx={completed || todo?.done ? { opacity: 0.8 } : ""}>
      <List disablePadding>
        <ListItem sx={{ p: 0 }}>
          <ListItemText
            sx={{ my: 0 }}
            primary={
              <Stack
                direction="row"
                gap="0.4rem"
              >
                <Avatar
                  color={completed || todo?.done ? "success" : "info"}
                  variant="rounded"
                  sx={{ width: "25px", height: "25px" }}
                >
                  {completed || todo?.done ? (
                    <LiaCheckCircle />
                  ) : (
                    <InfoCircle variant="Bold" />
                  )}
                </Avatar>
                <Typography>{todo?.weakness_title}</Typography>
              </Stack>
            }
            secondary={
              <Stack>
                <Typography variant="subtitle1">
                  { todo?.weakness_description}
                </Typography>
                <Stack
                  variant="caption"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid container spacing={1} marginTop={0.6}>
                    <Grid item>
                      <Chip
                        label={todo?.component}
                        variant={mode == ThemeMode.DARK ? "filled" : "light"}
                        color="info"
                        size="small"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label={todo?.exam_type.toUpperCase()}
                        variant={mode == ThemeMode.DARK ? "filled" : "light"}
                        color="info"
                        size="small"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label={`Task ${todo?.task_type}`}
                        variant={mode == ThemeMode.DARK ? "filled" : "light"}
                        color="info"
                        size="small"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        size="small"
                        label={moment(todo?.created_at).format("MMM Do YYYY")}
                        variant={mode == ThemeMode.DARK ? "filled" : "light"}
                        color="info"
                      />
                    </Grid>
                    <Grid item>
                      {completed ||
                        (todo?.done && (
                          <Chip
                            variant={
                              mode == ThemeMode.DARK ? "filled" : "light"
                            }
                            size="small"
                            label={`Completed At ${moment(todo?.done_at).format(
                              "MMM Do YYYY"
                            )}`}
                            color="success"
                          />
                        ))}
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            }
          />
        </ListItem>
        {!open &&         <Stack sx={{ marginTop: "1rem" }}>
          <Button
            onClick={() => setOpen(!open)}
            variant="contained"
            color="secondary"
            sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            {completed ? <TickCircle /> : null}
            Details
          </Button>
        </Stack>}
      </List>
      <Collapse in={open}>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Grid container spacing={1} marginTop="1rem">
            <Grid item xs={12}>
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={
                      <Stack direction="row" alignItems="center" gap="0.4rem">
                        <Avatar
                          color="error"
                          variant="rounded"
                          sx={{ width: "25px", height: "25px" }}
                        >
                          <MinusCirlce variant="Bold" />
                        </Avatar>
                        <Typography
                        >
                          <i>Issue Example</i>
                        </Typography>
                      </Stack>
                    }
                    secondary={
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        sx={{ marginTop: "1rem" }}
                      >
                        <Typography
                          variant="subtitle1"
                        >
                          <i>{todo?.weakness_example}</i>
                        </Typography>
                        <Link
                          to={`/report/${todo?.writing_id}`}
                          target="_blank"
                          style={{
                            fontSize: "14px",
                            color: "green",
                            textDecoration: "none",
                          }}
                        >
                          See Report
                        </Link>
                      </Stack>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <AuthDivider>
                <Typography variant="subtitle1">Solution</Typography>
              </AuthDivider>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "0.5rem" }}>
              <List
                disablePadding
              >
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={
                      <Stack direction="row" alignItems="center" gap="0.4rem">
                        <Avatar
                          color="secondary"
                          variant="rounded"
                          sx={{ width: "25px", height: "25px" }}
                        >
                          <TickCircle variant="Bold" />
                        </Avatar>
                        <Typography variant="subtitle1">
                          {todo?.solution_title}
                        </Typography>
                      </Stack>
                    }
                    secondary={
                      <Stack spacing={2} sx={{ marginTop: "1rem" }}>
                        <Typography variant="subtitle1">
                          {todo?.solution_description}
                        </Typography>{" "}
                        <Typography variant="subtitle2">
                          {todo?.solution_example}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              {todo?.done && (
                <Typography variant="caption" sx={{ marginTop: "12px" }}>
                  Completed At {moment(todo?.done_at).format("MMM Do YYYY")}
                </Typography>
              )}
              <Grid
                container
                spacing={2}
                marginTop={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Stack direction="row" justifyContent="space-between" width="100%">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(false)}
                  >
                 {todo.done ? <TickCircle/> : null } &nbsp;close
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => completeTodo(todo?.id)}
                    disabled={todo?.done}
                  >
                    {todo?.done ? "Completed" : "Complete"}
                  </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </motion.div>
      </Collapse>
    </MainCard>
  );
};

export default ImprovementTodo;
