import { ListItemText, Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import { InfoCircle, MinusCirlce, TickCircle } from "iconsax-react";
import React from "react";

function GrammerErrors({ grammerErrors }) {
  return (
    <MainCard titleVariant="h4" title="Grammer Errors">
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        maxHeight={300}
        minHeight={200}
        overflow="scroll"
        justifyContent="center"
      >
        {grammerErrors?.length ? (
          grammerErrors?.map((grammer) => (
            <div>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                  padding: "0",
                  gap: "4px",
                }}
              >
                <TickCircle fontSize={18} color="green" />
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  {grammer.correct}
                </p>
              </span>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                  padding: "0",
                  gap: "4px",
                }}
              >
                <MinusCirlce fontSize={18} color="#f43f5e" />
                <p style={{ margin: 0, padding: 0 }}>{grammer.incorrect}&nbsp;</p>
              </span>
            </div>
          ))
        ) : (
          <Stack direction="row">
                      <InfoCircle color="green"/> 
          <Typography variant="h4" textAlign="center">
            Congrats! looks like there is nothing here.
          </Typography>
          </Stack>
        )}
      </Stack>
    </MainCard>
  );
}

export default GrammerErrors;
