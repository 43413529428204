import {
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useGetTask } from "Api/hooks/useGetTask";
import { usePostWriting } from "Api/hooks/usePostWriting";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TransitionsModal from "sections/modal/TransitionsModal";
import MainCard from "../../MainCard";
import Avatar from "../../@extended/Avatar";
import { Document } from "iconsax-react";
import TaskForm from "./SimulatorTaskForm";
import useAuth from "hooks/useAuth";
import IELTSTimer from "./IELTSTimer";

function SimulatorForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const exam_type = searchParams.get("exam_type");
  const { task, isLoading } = useGetTask({
    exam_type,
    task_type: "",
  });
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(true);
  const [currentTask, setCurrentTask] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const { postWriting, isPosting } = usePostWriting();
  const task_1 = task?.find((t) => t.task_type == 1);
  const task_2 = task?.find((t) => t.task_type == 2);
  const [showLoginForm, setShowLoginForm] = useState();
  const { isLoggedIn } = useAuth();
  const [examData, setExamData] = useState({
    task1_description: "",
    task1_answer: "",
    task2_description: "",
    task2_answer: "",
    exam_type,
    task1_id: "",
    task2_id: "",
    service: "simulation",
    task1_duration: 0,
    task2_duration: 0,
  });
  console.log(examData);
  useEffect(() => {
    setExamData((prev) => ({
      ...prev,
      task1_description: task_1?.task_description,
      task2_description: task_2?.task_description,
      task1_id: task_1?.id,
      task2_id: task_2?.id,
    }));
  }, [task]);
  function startExam() {
    setWelcomeModalOpen(false);
    setIsRunning(true);
  }
  function submitHandler(values, submitWritingOnLogin = null) {
    const writingData = { ...examData };
    writingData.task2_answer = values.answer;
    if (submitWritingOnLogin != null && submitWritingOnLogin == true) {
      postWriting(writingData);
    }
    if (isLoggedIn) {
      postWriting(writingData);
    } else {
      setShowLoginForm(true);
    }
  }
  function nextTask(values) {
    setCurrentTask(2);
    setExamData((prev) => ({ ...prev, task1_answer: values.answer }));
  }

  if (isLoading || isPosting)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Stack
      key={currentTask}
      sx={{ filter: `${welcomeModalOpen ? "blur(20px)" : ""}` }}
    >
      {!showLoginForm && (
        <>
          {" "}
          <TransitionsModal
            open={welcomeModalOpen}
            setOpen={setWelcomeModalOpen}
            startExam={startExam}
          />
          <MainCard sx={{ marginBottom: "1rem" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={5}
            >
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar color="info" variant="rounded">
                      <Document variant="Bold" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={
                      <Typography variant="subtitle1">
                        Task {currentTask}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="subtitle2">
                        You should spend about {currentTask == 1 ? "20" : "40"}
                        &nbsp; minutes on this task. Write at least 50 words.
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
              <ListItemAvatar>
                <IELTSTimer
                  initialMinutes={currentTask == 1 ? 20 : 40}
                  initialSeconds={0}
                  key={currentTask}
                  isRunning={isRunning}
                  submitHandler={submitHandler}
                  setIsRunning={setIsRunning}
                  nextQuestion={nextTask}
                  currentTask={currentTask}
                  setTaskDuration={setExamData}
                />
              </ListItemAvatar>
            </Stack>
          </MainCard>
        </>
      )}
      <TaskForm
        task={currentTask == 1 ? task_1 : task_2}
        onSubmit={currentTask == 1 ? nextTask : submitHandler}
        currentTask={currentTask}
        welcomeModalOpen={welcomeModalOpen}
        showLoginForm={showLoginForm}
        setShowLoginForm={setShowLoginForm}
      />
    </Stack>
  );
}

export default SimulatorForm;
