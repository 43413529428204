import axios from "axios";
import { storePreviousUrl } from "./storePreviousUrl";
const ignorePaths = ["/login", "/register", "/pricing","/print","/example"];
const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 403 &&
      !ignorePaths.some((value) => window.location.href.includes(value))
    ) {
      sessionStorage.clear();
      storePreviousUrl();
      window.location.pathname = "/login";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
