import useCurrencyRate from "Api/hooks/useCurrencyRate";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Stack, Typography } from "@mui/material";

const currencies = [
  { title: "AUD", code: "AUD" },
  { title: "CAD", code: "CAD" },
  { title: "CAD", code: "EUR" },
  { title: "GBP", code: "GBP" },
  { title: "RUB", code: "RUB" },
  { title: "USD", code: "USD" },
  { title: "KWD", code: "KWD" },
  { title: "CHF", code: "CHF" },
];

function DynamicCurrency({ setCurrencyRate }) {
  const { currencyRate, loading: currencyRateLoading } = useCurrencyRate(
    "https://www.floatrates.com/daily/usd.json"
  );
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  function handleChange(e) {
    const newCurrency = e.target.value;
    setSelectedCurrency(newCurrency);
    if (newCurrency == "USD") {
      setCurrencyRate({ title: "usd", code: "USD", rate: 1 });
      return;
    }
    console.log(currencyRate["kwd"]);
    setCurrencyRate(currencyRate[newCurrency.toLowerCase()]);
    if (setCurrencyRate) {
      setCurrencyRate(currencyRate[newCurrency.toLowerCase()]);
    }
  }
  if(currencyRateLoading){
    return (
        <Stack>
            <Typography>Loading dynamic currencies...</Typography>
        </Stack>
    )
  }
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Currency</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedCurrency}
        label="Currency"
        onChange={handleChange}
      >
        {currencies.map((currency) => (
          <MenuItem key={currency.code} value={currency.code}>
            {currency.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DynamicCurrency;
