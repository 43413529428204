import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGetTask } from "Api/hooks/useGetTask";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainCard from "../../MainCard";
import { countwords } from "utils/contwords";
import { Formik } from "formik";
import { ArrowLeft, Refresh } from "iconsax-react";
import { useAutoSave } from "Api/hooks/useAutoSave";
import { usePostWriting } from "Api/hooks/usePostWriting";
import { useSaveWriting } from "Api/hooks/useSaveWriting";
import Login from "pages/auth/auth1/login";
import useAuth from "hooks/useAuth";
import ExitModal from "../../ExitModal";
import ExamServicesHeader from "../ExamServicesHeader";

function PracticeForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPaused, setIsPaused] = useState(true);
  const exam_type = searchParams.get("exam_type");
  const task_type = searchParams.get("task_type");
  const [submitType, setSubmitType] = useState();
  const formRef = useRef();
  const [exitModal, setExitModal] = useState(false);
  const navigate = useNavigate();
  const { autoSave } = useAutoSave();
  const { postWriting, isPosting } = usePostWriting();
  const { saveWriting, isSaving } = useSaveWriting();
  const [autoSaveId, setAutoSaveId] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState();
  const [taskDuration, setTaskDuration] = useState(0);
  const { isLoggedIn } = useAuth();
  const [isFirstInput,setIsFirstInput] = useState(true)
  console.log(taskDuration);
  const { task, isLoading, isFetching, error, refetch } = useGetTask({
    exam_type,
    task_type,
  });
  const validateExam = (values) => {
    let errors = {};
    if (!values.answer) {
      errors.answer = "Task Answer is required";
    } else if (
      values.answer.split(" ").filter((word) => word !== "").length < 50
    ) {
      errors.answer = "You should write at least 50 words";
    }
    return errors;
  };
  function submitHandler(values, submitWritingOnLogin = null) {
    const task1_data = {
      task1_description: task[0]?.task_description,
      task1_answer: values.answer,
      exam_type,
      task1_id: task[0]?.id,
      task1_duration: taskDuration,
    };
    const task2_data = {
      task2_description: task[0]?.task_description,
      task2_answer: values.answer,
      exam_type,
      task2_id: task[0]?.id,
      task2_duration: taskDuration,
    };
    const examData = task_type == 1 ? task1_data : task2_data;
    examData.service = "practice";
    examData.status = submitType;
    if (submitWritingOnLogin != null && submitWritingOnLogin == true) {
      {
        submitType == "submitted" && postWriting(examData);
      }
      {
        submitType == "draft" && saveWriting(examData);
      }
    }
    if (isLoggedIn) {
      {
        submitType == "submitted" && postWriting(examData);
      }
      {
        submitType == "draft" && saveWriting(examData);
      }
    } else {
      setShowLoginForm(true);
    }
  }
  useEffect(() => {
    const interval = setInterval(autoSaveHandler, 30000);
    return () => clearInterval(interval);
  }, [formRef.current]);
  function autoSaveHandler() {
    const task1_data = {
      task1_description: task && task[0]?.task_description,
      task1_answer: formRef?.current?.values?.answer,
      exam_type,
      task1_id: task[0]?.id,
      task1_duration: taskDuration,
    };
    const task2_data = {
      task2_description: task && task[0]?.task_description,
      task2_answer: formRef?.current?.values?.answer,
      exam_type,
      task2_id: task[0]?.id,
      task2_duration: taskDuration,
    };
    const examData = task_type == 1 ? task1_data : task2_data;
    examData.service = "practice";
    examData.status = "draft";
    examData.id = autoSaveId;
    if ((isLoggedIn && formRef?.current?.values?.answer != "") || undefined) {
      autoSave(examData, { onSuccess: (data) => setAutoSaveId(data.id) });
    }
  }
  const handleFirstInput = (event) => {
    if (isFirstInput) {
      setIsPaused(false)
      setIsFirstInput(false);
    }
  };
  useEffect(()=>{
    if(isPaused){
      setIsFirstInput(true)
    }
  },[isPaused])
  if (isLoading || isFetching || isPosting || isSaving)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={{
        answer: "",
      }}
      validate={(values) => validateExam(values)}
      onSubmit={(values) => submitHandler(values)}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <>
          {!showLoginForm && (
            <Stack>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ExamServicesHeader
                  isPaused={isPaused}
                  setIsPaused={setIsPaused}
                    currentTask={task_type}
                    setTaskDuration={setTaskDuration}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MainCard
                    sx={{ maxHeight: "500px", overflow: "scroll" }}
                    title={
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle1">
                          Task {task_type}
                        </Typography>
                        <Typography variant="subtitle1">
                          Exam {exam_type.toUpperCase()}
                        </Typography>
                      </Stack>
                    }
                  >
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: task && task[0]?.task_description,
                      }}
                    ></Typography>
                    {exam_type == "academic" && task_type == 1 && (
                      <img
                        src={task && task[0]?.image}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          marginTop: "1rem",
                        }}
                      />
                    )}
                  </MainCard>
                  <Button
                    sx={{ marginTop: "1rem" }}
                    onClick={() => refetch()}
                    endIcon={<Refresh />}
                    variant="contained"
                    color="secondary"
                  >
                    Change my question
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <form onSubmit={handleSubmit} noValidate>
                    <MainCard
                      sx={{ height: "500px" }}
                      title={
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="subtitle1">
                            Task answer
                          </Typography>
                          <Typography>
                            {countwords(values.answer || "")} words
                          </Typography>
                        </Stack>
                      }
                      subheader="Enter your task answer here"
                    >
                      <TextField
                        id="answer"
                        multiline
                        rows={15}
                        name="answer"
                        fullWidth
                        value={values.answer}
                        onChange={handleChange}
                        onInput={handleFirstInput}
                      />
                      {touched.answer && errors.answer && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-answer"
                        >
                          {errors.answer}
                        </FormHelperText>
                      )}
                    </MainCard>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                      sx={{ marginTop: "1rem" }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setExitModal(true)}
                      >
                        <ArrowLeft />
                      </Button>
                      <Button
                        variant="outlined"
                        type="submit"
                        onClick={() => setSubmitType("draft")}
                      >
                        Save as draft
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => {
                          setSubmitType("submitted");
                        }}
                      >
                        Finish
                      </Button>
                    </Stack>
                  </form>
                </Grid>
              </Grid>
            </Stack>
          )}
          {showLoginForm && (
            <Login
              values={values}
              triggerLoginForm={() => setShowLoginForm(false)}
              submitHandler={submitHandler}
            />
          )}
          <ExitModal
            save={() => autoSaveHandler()}
            open={exitModal}
            setOpen={setExitModal}
          />
        </>
      )}
    </Formik>
  );
}

export default PracticeForm;
