import { Button, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

export function getTableStatusAction(row) {
    switch (row?.status?.toLowerCase()) {
      case "done": {
        return (
          <Button component={Link} to={`/result/${row?.id}`} target="_self">
            View Report
          </Button>
        );
      }
      case "processing": {
        return <CircularProgress color="primary" />;
      }
      case "waiting": {
        return (
          <Button
            component={Link}
            to={`/payment?examId=${row?.id}`}
          >
            Pay
          </Button>
        );
      }
      case "draft": {
        return (
          <Button component={Link} to={`/exam/scoring?examId=${row?.id}`}>
            Edit
          </Button>
        );
      }
      default: {
        return null;
      }
    }
  }