import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import {
  Box,
  Button,
  Grid,
  ListItemButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import Avatar from "components/@extended/Avatar";
import IconButton from "components/@extended/IconButton";

// assets
import { More } from "iconsax-react";
import { useNavigate } from "react-router";

// ==============================|| CHART WIDGET - ECOMMERCE CARD  ||============================== //

const EcommerceDataCard = ({
  title,
  count,
  percentage,
  color,
  iconPrimary,
  children,
  to,
  onClick
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  return (
    <MainCard
      sx={{height: "400px", cursor: "pointer" }}
      onClick={onClick}
    >
      <Stack
        sx={{ height: "350px" }}
        direction="column"
        justifyContent="space-between"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar variant="rounded" color={color}>
                  {iconPrimary}
                </Avatar>
                <Typography variant="h4">{title}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <MainCard
              content={false}
              border={false}
              sx={{ bgcolor: "background.default",maxHeight:"200px",overflow:"scroll" }}
            >
              <Box sx={{ p: 3, pb: 1.25 }}>{children}</Box>
            </MainCard>
          </Grid>
        </Grid>
        <Button variant="contained">SELECT</Button>
      </Stack>
    </MainCard>
  );
};

EcommerceDataCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.object,
  children: PropTypes.node,
  iconPrimary: PropTypes.node,
};

export default EcommerceDataCard;
