// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import { ArrowSwapHorizontal } from 'iconsax-react';
import { ThemeMode } from 'config';

const DashboardDataCard = ({title,amount,icon,color}) => {
  const theme = useTheme();
  function getColor(){
    switch(color){
        case "info":{
            return theme.palette.info.main
        }
        case "success":{
            return theme.palette.success.main
        }
        case "warning" :{
            return theme.palette.warning.main
        }
        case "error" : {
            return theme.palette.error.main
        }
        case "secondary" : {
            return theme.palette.secondary.light
        }
        default:{
            return theme.palette.primary.main
        }
    }
  }
  return (
    <MainCard
      content={false}
      sx={{
        bgcolor: alpha(getColor(), 1),
        color: 'common.white',
        '&:after': {
          content: '""',
          background: `linear-gradient(245deg, transparent 25.46%, rgba(0, 0, 0, 0.2) 68.77%, rgba(0, 0, 0, 0.3) 81.72%)`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          opacity: 0.6
        }
      }}
    >
      <Box sx={{ p: 2, position: 'inherit', zIndex: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar
            variant="rounded"
            type="filled"
            size="lg"
            sx={{ bgcolor: getColor() }}
          >
            {icon}
          </Avatar>
            <Typography variant='h4'>{title}</Typography>
          </Stack>
          <Typography variant="h3">{amount}</Typography>
        </Stack>
      </Box>
    </MainCard>
  );
};

export default DashboardDataCard;
