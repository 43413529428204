import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// fonts
import 'assets/fonts/inter/inter.css';

// scroll bar
import 'simplebar/dist/simplebar.css';


// apex-chart
import 'assets/third-party/apex-chart.css';
// project-imports
import App from './App';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';

const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient()
// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <QueryClientProvider client={queryClient}>
  <ReduxProvider store={store}>
    <ConfigProvider>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>
  </QueryClientProvider>
);
