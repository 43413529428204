import { useQuery } from "@tanstack/react-query"
import { getTaskApi } from "Api/providers/taskProvider"

export function useGetTask({exam_type,task_type}){
    const {data:task,isLoading,isFetching,error,refetch} = useQuery({
        queryKey:["task"],
        keepPreviousData:true,
    queryFn:()=>getTaskApi({exam_type,task_type}),
    retry:false,
    refetchOnWindowFocus:false,
    refetchOnReconnect:false,
    })
    return {task,isLoading,isFetching,error,refetch}
}