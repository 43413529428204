// material-ui
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";

// assets
import { Link } from "react-router-dom";
import useImprovments from "Api/hooks/useImprovments";
import NoActiveImprovements from "./NoActiveImprovements";
import ImprovementTodo from "./ImprovementTodo";
import { useState } from "react";

const DashboardImprovements = ({ improvements }) => {
  const { refetch, complete, isCompleting, isLoading } = useImprovments();
  const [activeCount,setActiveCount] = useState(improvements?.filter(
    (task) => task.done == false
  ).length)
  if (isLoading)
    return (
      <Stack marginTop="2rem">
        <CircularProgress style={{ margin: "auto" }} />
      </Stack>
    );
  return (
    <MainCard
      sx={{ maxHeight: "439px", overflow: "scroll" }}
      title={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Improvements</Typography>
          <Button component={Link} to="/improvements">
            View All
          </Button>
        </Stack>
      }
    >
      <Grid container justifyContent="center" spacing={1}>
        {improvements?.length == 0 && <NoActiveImprovements />}
        {improvements?.map((todo) => (
          <Grid item xs={12} sm={10}>
            <ImprovementTodo
              todo={todo}
              refetch={refetch}
              complete={complete}
              setActiveCount={setActiveCount}
            />
          </Grid>
        ))}
      </Grid>
    </MainCard>
  );
};

export default DashboardImprovements;
