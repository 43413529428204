import { Chip, ListItemAvatar } from "@mui/material";
import { Moon, Sun } from "iconsax-react";
import React from "react";
import Avatar from "./@extended/Avatar";
import useConfig from "hooks/useConfig";
import { ThemeMode } from "config";

function ThemeToggler() {
  const { mode, onChangeMode } = useConfig();
  const handleModeChange = () => {
    const value = mode == "dark" ? ThemeMode.LIGHT : ThemeMode.DARK;
    onChangeMode(value);
  };
  return (
    <Avatar
      variant="light"
      color="info"
      sx={{ margin: "0" }}
      onClick={() => handleModeChange()}
    >
      {mode == "dark" ? <Sun /> : <Moon />}
    </Avatar>
  );
}

export default ThemeToggler;
