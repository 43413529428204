import { useMutation } from "@tanstack/react-query";
import {
  resendEmailVerificationCode,
  verifyEmailApi,
} from "Api/providers/emailProviders";

export default function useVerifyEmail() {
  const { isPending: isVerifying, mutateAsync: verifyEmail } = useMutation({
    mutationFn: verifyEmailApi,
  });
  const { isPending: isResending, mutateAsync: resendCode } = useMutation({
    mutationFn: resendEmailVerificationCode,
  });
  return { verifyEmail, resendCode, isVerifying, isResending };
}
