import PropTypes from "prop-types";
import { useState, useEffect } from "react";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

// third-party
import ReactApexChart from "react-apexcharts";

// project-imports
import { ThemeMode } from "config";


const EcommerceDataChart = ({ color, value, chartHeight,chartFontSize }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const adjustedValue = (value * 100) / 9;
  // chart options
  const areaChartOptions = {
    chart: {
      id: "new-stack-chart",
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 0,
          size: "80%",
          background: "transparent",
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
        },
        track: {
          background: alpha(color, 0.5),
          strokeWidth: "50%",
          margin: 0, // margin is in pixels
        },

        dataLabels: {
          show: true,
          name: {
            show: false,
          },
          value: {
            formatter: function () {
              return value; // Display the original value without conversion
            },
            offsetY: 7,
            color: color,
            fontSize: chartFontSize,
            fontWeight: "700",
            show: true,
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
  };

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [color],
      theme: {
        mode: mode === ThemeMode.DARK ? "dark" : "light",
      },
    }));
  }, [color, mode, primary, secondary, line, theme]);

  const [series] = useState([adjustedValue]);

  return (
    <Box sx={{ maxHeight: "170px" }}>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={chartHeight}
      />
    </Box>
  );
};

EcommerceDataChart.propTypes = {
  color: PropTypes.string,
};


const EcommerceRadial = ({
  color,
  value,
  chartWidth = 120,
  chartHeight = 150,
  height = "",
  chartFontSize = "20px"
}) => {
  return (
    <EcommerceDataChart color={color} value={value} chartHeight={chartHeight} chartFontSize={chartFontSize}/>
  );
};

EcommerceRadial.propTypes = {
  color: PropTypes.string,
};

export default EcommerceRadial;
