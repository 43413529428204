import { Link, useNavigate } from "react-router-dom";

import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import Logo from "components/logo";
import useAuth from "hooks/useAuth";
import AuthWrapper from "sections/auth/AuthWrapper";
import AuthLogin from "sections/auth/auth-forms/AuthLogin";
import useUser from "Api/hooks/useUser";
import axios from "utils/axios";
import AuthDivider from "components/AuthDivider";
const Login = ({
  triggerLoginForm = null,
  values = null,
  submitHandler = null,
}) => {
  const { isLoggedIn } = useAuth();
  const { user, refetch, isLoading } = useUser();
  const navigate = useNavigate();
  function setUser(response) {
    localStorage.setItem("serviceToken", response.data.data.access_token);
    refetch();
    navigate("/");
  }
  function authWithGoogle(credential) {
    axios.post("/google", { credential }).then((res) => setUser(res));
  }
  window.onload = function () {
    window.google.accounts.id.initialize({
      client_id:
        "902458892526-ruo700k0ucct832fhkli90pfs6ficja9.apps.googleusercontent.com",
      callback: (res) => {
        authWithGoogle(res.credential);
      },
      itp_support: true,
      auto_select: false,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("g-signin2"),
      { theme: "outline", size: "large", width: 300 }
    );
  };
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <AuthWrapper triggerLoginForm={triggerLoginForm}>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Logo to="/dashboard"/>
        </Grid>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <div
            id="g-signin2"
            data-onsuccess="onSignIn"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
        </Grid>
        <Grid item xs={12}>
          <AuthDivider>
            <Typography variant="body1">OR</Typography>
          </AuthDivider>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            <Typography variant="h4">Login with your email</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthLogin
            forgot="/auth/forgot-password"
            writingValues={values}
            triggerLoginForm={triggerLoginForm}
            submitHandler={submitHandler}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">Dont have an account?</Typography>
            <Link
              to={isLoggedIn ? "/auth/register" : "/register"}
              style={{
                textDecoration: "none",
                color: "green",
              }}
            >
              Create account
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12} >
        <Typography variant="body2">
                  By using our services, you agree to our &nbsp;
                  <Link variant="subtitle2" component={Link} to="https://writingchex.com/terms" style={{color:"green"}}>
                    Terms of Service
                  </Link>
                  &nbsp; and &nbsp;
                  <Link variant="subtitle2" component={Link} to="https://writingchex.com/privacy" style={{color:"green"}}>
                    Privacy Policy
                  </Link>
                </Typography>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
