import {
  Drawer,
  Stack,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Grid,
  ListItemText,
  Button,
} from "@mui/material";
import useImprovments from "Api/hooks/useImprovments";
import { useState } from "react";
import {
  LiaCheckCircle,
  LiaInfoCircleSolid,
  LiaTimesCircle,
} from "react-icons/lia";
import ShowMore from "react-show-more";
import "../showMore.css";
import { Clock, Document, Task, TickCircle } from "iconsax-react";
import moment from "moment";
import MainCard from "components/MainCard";
import { GrDocumentText } from "react-icons/gr";
import ImprovementTodo from "components/Improvements/ImprovementTodo";
import Avatar from "components/@extended/Avatar";
import { motion, AnimatePresence } from "framer-motion";
function ImprovementCard({ writing, components, refetch }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("task achievement");
  const filteredTasks = writing.improvements.filter(
    (task) => task.component.toLowerCase() == value.toLowerCase()
  );
  const sortedAndFilteredTasks = filteredTasks?.sort((a, b) => {
    if (a.done === b.done) {
      return 0;
    }
    return a.done ? 1 : -1;
  });
  const { complete } = useImprovments();
  const [activeCount, setActiveCount] = useState(
    writing.improvements?.filter((task) => task.done == false).length
  );
  console.log(sortedAndFilteredTasks)
  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
        <Stack padding={4} spacing={2} sx={{ maxWidth: "400px" }}>
          <Stack
            marginBottom={2}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">
              Your Writings improvement tasks
            </Typography>
            <LiaTimesCircle
              fontSize="24px"
              style={{ cursor: "pointer" }}
              color="red"
              onClick={() => setOpen(false)}
            />
          </Stack>
          <Divider />
          <Stack>
            <List disablePadding>
              <ListItem sx={{ p: 0 }}>
                <ListItemAvatar>
                  <Avatar color="warning" variant="rounded">
                    <Document variant="Bold" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ my: 0 }}
                  primary={
                    <Typography>
                      {moment(writing?.created_at).format("MMM Do YYYY")}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle1">
                      {writing?.exam_type.toUpperCase()}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Stack>
          <Divider />
          <Stack>
            <Typography variant="h5">
              Filter your tasks based on their component
            </Typography>
            <Grid container alignItems="center" spacing={1} mt={1}>
              {components.map((c) =>
            
              {
                console.log(c.improvements[0])
                return(
                <Grid item xs={6}>
                  <Button startIcon={c?.improvements[0]?.filter((task)=>!task.done).length == 0 ? <TickCircle/> : null}
                    fullWidth
                    sx={{height:'70px'}}
                    size="small"
                    variant="contained"
                    color={
                      c.value.toLowerCase() == value.toLowerCase()
                        ? "success"
                        : "secondary"
                    }
                    onClick={() => setValue(c.value)}
                  >
         {c.value}
                  </Button>
                </Grid>
              )})}
            </Grid>
          </Stack>
          <Divider />
          <Stack>
            <Grid container spacing={2}>
              {!sortedAndFilteredTasks.length && (
                <Stack direction="row" spacing={2} mt={2}>
                  <LiaInfoCircleSolid color="lightblue" fontSize="2.5rem" />
                  <Typography variant="h5">
                    No active tasks were found for the selected component!
                  </Typography>
                </Stack>
              )}
              <ul style={{ paddingLeft: "1rem" }}>
                <AnimatePresence>
                  {sortedAndFilteredTasks.map((todo) => (
                    <motion.li style={{listStyle:'none'}}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    layout
                    transition={{ type: 'spring', stiffness: 300, damping: 20, duration: 0.6 }}
                    >
                      <Grid item>
                        <ImprovementTodo
                          refetch={refetch}
                          todo={todo}
                          complete={complete}
                          setActiveCount={setActiveCount}
                        />
                      </Grid>
                    </motion.li>
                  ))}
                </AnimatePresence>
              </ul>
            </Grid>
          </Stack>
        </Stack>
      </Drawer>
      <MainCard
        sx={{
          overflow: "auto",
          opacity: activeCount != 0 ? "" : 0.7,
        }}
        title={
          <List disablePadding sx={{ maxHeight: "50px" }}>
            <ListItem sx={{ p: 0 }}>
              <ListItemAvatar>
                <Avatar color="info" variant="rounded">
                  <GrDocumentText />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ my: 0 }}
                primary={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Task {writing?.task_type}</Typography>
                    {activeCount != 0 && (
                      <span
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "100%",
                          backgroundColor: "red",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "20px",
                        }}
                      >
                        {activeCount}
                      </span>
                    )}
                    {!activeCount != 0 && (
                      <LiaCheckCircle color="green" fontSize="34px" />
                    )}
                  </Stack>
                }
                secondary={
                  <Typography variant="subtitle1">
                    {writing?.exam_type?.toUpperCase()}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        }
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          spacing={3}
          sx={{ minHeight: "200px" }}
        >
          <Stack>
            <Typography variant="subtitle1">Task answer</Typography>
            <ShowMore lines={2} anchorClass="showMore">
              <Typography
                dangerouslySetInnerHTML={{
                  __html: writing?.task_answer,
                }}
                sx={{ wordWrap: "break-word" }}
              ></Typography>
            </ShowMore>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{ mt: 1.5, width: "100%" }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <Task size={14} /> {writing?.improvements?.length} Tasks
              <Clock size={14} style={{ marginLeft: 8 }} />{" "}
              {moment(writing?.created_at).format("MMM Do YYYY")}
            </Typography>
            <Button
              variant="contained"
              color="info"
              onClick={() => setOpen(true)}
            >
              Open
            </Button>
          </Stack>
        </Stack>
      </MainCard>
    </>
  );
}
export default ImprovementCard;
