import {
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import MainCard from "../../MainCard";
import { Formik } from "formik";
import { countwords } from "utils/contwords";
import { ArrowLeft } from "iconsax-react";
import Login from "pages/auth/auth1/login";

export default function SimulatorTaskForm({
  task,
  onSubmit,
  currentTask,
  welcomeModalOpen,
  showLoginForm,
  setShowLoginForm,
}) {
  const navigate = useNavigate();
  const validateExam = (values) => {
    let errors = {};
    if (!values.answer) {
      errors.answer = "Task Answer is required";
    } else if (
      values.answer.split(" ").filter((word) => word !== "").length < 1
    ) {
      errors.answer = "You should write at least 50 words";
    }
    return errors;
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{ filter: `${welcomeModalOpen ? "blur(20px)" : ""}` }}
    >
      {!showLoginForm && (
        <Grid item xs={12} sm={6}>
          <MainCard
            sx={{ maxHeight: "500px", overflow: "scroll" }}
            title={
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle1">
                  Task {task?.task_type}
                </Typography>
                <Typography variant="subtitle1">
                  Exam {task?.exam_type?.toUpperCase()}
                </Typography>
              </Stack>
            }
          >
            <Typography
              dangerouslySetInnerHTML={{
                __html: task && task?.task_description,
              }}
            ></Typography>
            {task?.exam_type == "academic" && task?.task_type == 1 && (
              <img
                src={task && task?.image}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  marginTop: "1rem",
                }}
              />
            )}
          </MainCard>
        </Grid>
      )}
      <Grid item xs={12} sm={showLoginForm ? 12 : 6}>
        <Formik
          onSubmit={(values) => onSubmit(values)}
          initialValues={{
            answer: "",
          }}
          validate={(values) => validateExam(values)}
        >
          {({ errors, handleChange, handleSubmit, values, touched }) => (
            <>
              {!showLoginForm && (
                <form onSubmit={handleSubmit}>
                  <MainCard
                    sx={{ height: "500px" }}
                    title={
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle1">Task answer</Typography>
                        <Typography>
                          {countwords(values.answer || "")} words
                        </Typography>
                      </Stack>
                    }
                    subheader="Enter your task answer here"
                  >
                    <TextField
                      id="answer"
                      multiline
                      rows={15}
                      name="answer"
                      fullWidth
                      // value={values.answer}
                      onChange={handleChange}
                    />
                    {touched.answer && errors.answer && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-answer"
                      >
                        {errors.answer}
                      </FormHelperText>
                    )}
                  </MainCard>
                  <Stack
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                    sx={{ marginTop: "1rem" }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => navigate("/exam?simulator")}
                    >
                      <ArrowLeft />
                    </Button>
                    <Button variant="contained" type="submit">
                      {currentTask == 1 ? "Next Task" : "Submit"}
                    </Button>
                  </Stack>
                </form>
              )}
              {showLoginForm && (
                <Login
                  values={values}
                  triggerLoginForm={() => setShowLoginForm(false)}
                  submitHandler={onSubmit}
                />
              )}
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
