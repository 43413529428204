import { useQuery } from "@tanstack/react-query";
import { getExamApi } from "Api/providers/examProviders";

export default function useGetExam(id) {
  const {
    data: exam,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => getExamApi(id),
    queryKey: ["single-exam"],
    staleTime:0,
    enabled:!!id
  });
  return { exam, isLoading, refetch };
}
