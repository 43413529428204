// material-ui
import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// project-imports
import MainCard from "components/MainCard";
import Avatar from "components/@extended/Avatar";

// assets
import { TableDocument } from "iconsax-react";
import { Link } from "react-router-dom";
import useGetResult from "Api/hooks/useGetResult";
import EcommerceRadial from "components/report/ScoreChart";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import ApexBarChart from "sections/charts/apexchart/ApexBarChart";
// ===========================|| DATA WIDGET - USER PERSONAL DATA ||=========================== //

const ReportSummary = ({
  writing,
  examWritings,
  setSelectedTask,
  selectedTask,
}) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const smallerScreen = useMediaQuery("(max-width:350px)");
  const handleChange = (event, newValue) => {
    setSelectedTask(newValue);
  };
  const color = writing?.result?.overall_band_score > 4 ? "#4ade80" : "#f43f5e";
  console.log(writing, selectedTask);
  return (
    <>
      {/* <Stack sx={{ marginBottom: "1rem" }}>
        <Tabs
          value={selectedTask}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          {examWritings?.map((writing) => {
            return (
              <Tab
                value={Number(writing?.task_type)}
                label={`Task ${writing?.task_type} Report`}
              />
            );
          })}
        </Tabs>
      </Stack> */}
      <MainCard
        title={
          <List disablePadding>
            <Stack direction="row" alignItems="center">
              <ListItem sx={{ p: 0 }}>
                <ListItemAvatar>
                  <Avatar color="error" variant="rounded">
                    <TableDocument variant="Bold" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{ my: 0 }}
                  primary={<Typography>Task {writing?.task_type}</Typography>}
                  secondary={
                    <Typography variant="subtitle1">
                      Score {writing?.result?.overall_band_score?.toFixed(1)}
                    </Typography>
                  }
                />
              </ListItem>
            </Stack>
          </List>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Typography variant="subtitle1">Performance Summary</Typography>
            <Typography variant="body1"
              dangerouslySetInnerHTML={{ __html: writing?.result["comment"] || ""}}
            ></Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              direction="row"
              alignItems="center"
              // spacing={2}
              // sx={{ px: 2 }}
              justifyContent="center"
              sx={{maxHeight:'100px'}}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  alignItems: "center",
                  ml: !isMobile && "2rem",
                  position:'relative'
                }}
              >
                <EcommerceRadial
                key={writing?.result?.overall_band_score}
                  chartHeight={smallerScreen ? 100 : !isMobile ? 170 : 150}
                  chartWidth={290}
                  color={color}
                  value={writing.result.overall_band_score?.toFixed(1)}
                  chartFontSize="30px"
                />
                <Typography sx={{ textAlign: "center" ,position:'absolute',bottom:'-5px'}} variant="caption">
                  Overall Score
                  <br />
                  The candidate scored{" "}
                  {writing?.result?.overall_band_score.toFixed(1)} out of 9.0
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ApexBarChart writing={writing} key={writing?.result?.overall_band_score}/>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default ReportSummary;
