import axios from "../../utils/axios";

export function getExamsApi() {
  return axios.get("/exams").then(({ data }) => data.data);
}
export function getResultApi(writingId) {
  return axios.get(`/writings/${writingId}`).then(({ data }) => data.data);
}
export function editedExamApi(id) {
  return axios.get(`/exams/${id}`).then(({ data }) => data.data);
}
export function saveWritingApi(writingData) {
  return axios.post(`/exams/`, writingData).then(({ data }) => data.data);
}
export function getDraftsApi() {
  return axios.get("exams/drafts").then(({ data }) => data.data);
}
export function writingListApi() {
  return axios.get("/writings").then(({ data }) => data.data);
}
export function spellingTaskApi() {
  return axios.get("/spelling-practice").then(({ data }) => data.data);
}
