import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  Modal,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MainCard from "../../MainCard";
import { Formik } from "formik";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import useGetEditedExam from "Api/hooks/useGetEditedExam";
import { usePostWriting } from "Api/hooks/usePostWriting";
import { useSaveWriting } from "Api/hooks/useSaveWriting";
import { countwords } from "utils/contwords";
import SingleFileUpload from "../../third-party/dropzone/SingleFile";
import { useAutoSave } from "Api/hooks/useAutoSave";
import useAuth from "hooks/useAuth";
import Login from "pages/auth/auth1/login";
import ExitModal from "../../ExitModal";
import ExamServicesHeader from "../ExamServicesHeader";
function ScoringForm() {
  const { isLoggedIn } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef(null);
  const [showLoginForm, setShowLoginForm] = useState();
  const isEdit = searchParams.has("examId");
  const examId = searchParams.get("examId");
  const { postWriting, isPosting } = usePostWriting();
  const { saveWriting, isSaving } = useSaveWriting();
  const [submitType, setSubmitType] = useState();
  const [autoSaveId, setAutoSaveId] = useState(null);
  const [exitModal, setExitModal] = useState(false);
  const { autoSave, lastSaved } = useAutoSave();
  const [taskDuration, setTaskDuration] = useState(0);
  const { editedExam, isEditedExamLoading } = useGetEditedExam(examId);
  const [isPaused, setIsPaused] = useState(false);
  const exam_type = editedExam?.type || searchParams.get("exam_type");
  const task_type =
    editedExam?.writings[0]?.task_type || searchParams.get("task_type");
  const hasFile = exam_type == "academic" && task_type == 1;
  const [taskDetail, setTaskDetail] = useState({
    task_answer: "",
    task_description: "",
  });
  useEffect(() => {
    setTaskDetail({
      task_answer: editedExam?.writings[0]?.task_answer,
      task_description: editedExam?.writings[0]?.task_description.replace(
        /<br\s*\/?>/g,
        ""
      ),
    });
  }, [editedExam]);
  const validateExam = (values) => {
    let errors = {};
    if (!values.answer) {
      errors.answer = "Task Answer is required";
    } else if (
      values.answer.split(" ").filter((word) => word !== "").length < 50
    ) {
      errors.answer = "You should write at least 50 words";
    }
    if (!values.description) {
      errors.description = "Task description is reqired";
    }
    return errors;
  };
  function submitHandler(values, submitWritingOnLogin = null) {
    const task1_data = {
      task1_description: values.description,
      task1_answer: values.answer,
      exam_type,
      file: !isEdit ? (values?.files ? values.files[0] : null) : null,
      task1_duration: taskDuration,
    };
    const task2_data = {
      task2_description: values.description,
      task2_answer: values.answer,
      exam_type,
      task2_duration: taskDuration,
    };
    const examData = task_type == 1 ? task1_data : task2_data;
    examData.status = submitType;
    examData.id = examId ? Number(examId) : undefined;
    examData.service = "score_my_writing";
    console.log(examData);
    const formData = new FormData();
    Object.keys(examData).map((key) => {
      if (examData[key] != null) {
        formData.append(key, examData[key]);
      }
    });
    if (submitWritingOnLogin != null && submitWritingOnLogin == true) {
      {
        submitType == "submitted" && postWriting(formData);
      }
      {
        submitType == "draft" && saveWriting(formData);
      }
    }
    if (isLoggedIn) {
      {
        submitType == "submitted" && postWriting(formData);
      }
      {
        submitType == "draft" && saveWriting(formData);
      }
    } else {
      setShowLoginForm(true);
    }
  }
  function autoSaveHandler() {
    const task1_data = {
      task1_description: formRef?.current?.values?.description,
      task1_answer: formRef?.current?.values?.answer,
      exam_type,
      file: formRef.current?.values?.file,
    };
    const task2_data = {
      task2_description: formRef?.current?.values?.description,
      task2_answer: formRef?.current?.values?.answer,
      exam_type,
    };
    const examData = task_type == 1 ? task1_data : task2_data;
    examData.status = "draft";
    examData.id = examId ? Number(examId) : autoSaveId;
    examData.service = editedExam?.service || "score_my_writing";
    const formData = new FormData();
    Object.keys(examData).map((key) => {
      if (examData[key] != null) {
        formData.append(key, examData[key]);
      }
    });
    if (
      isLoggedIn &&
      formRef?.current?.values?.answer != "" &&
      formRef?.current?.values?.description != ""
    ) {
      autoSave(formData, { onSuccess: (data) => setAutoSaveId(data.id) });
    }
  }
  useEffect(() => {
    const interval = setInterval(autoSaveHandler, 20000);
    return () => clearInterval(interval);
  }, [formRef.current]);

  if (isEditedExamLoading || isPosting || isSaving)
    return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          description: isEdit ? taskDetail.task_description : "",
          answer: isEdit ? taskDetail.task_answer : "",
          file: null,
        }}
        validate={validateExam}
        onSubmit={(values) => submitHandler(values)}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <>
            {!showLoginForm && (
              <form onSubmit={handleSubmit} noValidate>
                <Stack>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                      <ExamServicesHeader
                        setTaskDuration={setTaskDuration}
                        currentTask={task_type}
                        isPaused={isPaused}
                        setIsPaused={setIsPaused}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <MainCard
                        title="Task Description"
                        subheader="Enter your task description here"
                        sx={{
                          height: "500px",
                          maxHeight: "500px",
                          overflow: "scroll",
                        }}
                      >
                        <TextField
                          id="description"
                          multiline
                          rows={15}
                          name="description"
                          fullWidth
                          value={values.description}
                          onChange={handleChange}
                          disabled={isPaused}
                        />
                        {touched.description && errors.description && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-description"
                          >
                            {errors.description}
                          </FormHelperText>
                        )}
                        {isEdit && (
                          <img
                            src={editedExam?.writings[0]?.file}
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              marginTop: "1rem",
                            }}
                          />
                        )}
                      </MainCard>
                      {!isEdit && hasFile && (
                        <MainCard
                          sx={{ marginTop: "1rem" }}
                          title="Task Image"
                          subheader="Please upload the task image here"
                        >
                          <SingleFileUpload
                            setFieldValue={setFieldValue}
                            file={values.files}
                          />
                        </MainCard>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MainCard
                        sx={{ height: "500px" }}
                        title={
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="subtitle1">
                              Task answer
                            </Typography>
                            <Typography>
                              {countwords(values.answer || "")} words
                            </Typography>
                          </Stack>
                        }
                        subheader="Enter your task answer here"
                      >
                        <TextField
                          id="answer"
                          multiline
                          rows={15}
                          name="answer"
                          fullWidth
                          value={values.answer}
                          onChange={handleChange}
                          disabled={isPaused}
                        />
                        {touched.answer && errors.answer && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-answer"
                          >
                            {errors.answer}
                          </FormHelperText>
                        )}
                        <Typography variant="caption" color="GrayText">
                          Last saved {lastSaved || "just now"}
                        </Typography>
                      </MainCard>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setExitModal(true)}
                      >
                        <ArrowLeft />
                      </Button>
                      <Button
                        variant="outlined"
                        type="submit"
                        onClick={() => setSubmitType("draft")}
                      >
                        Save as draft
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={() => setSubmitType("submitted")}
                      >
                        Finish
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            )}
            {showLoginForm && (
              <Login
                values={values}
                triggerLoginForm={() => setShowLoginForm(false)}
                submitHandler={submitHandler}
              />
            )}
            <ExitModal
              open={exitModal}
              setOpen={setExitModal}
              save={() => autoSaveHandler()}
            />
          </>
        )}
      </Formik>
    </>
  );
}

export default ScoringForm;
