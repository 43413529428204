import { useMutation } from "@tanstack/react-query";
import { saveWritingApi } from "Api/providers/writingProviders";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

export function useAutoSave() {
  const [lastSaved,setLastSaved] = useState()
    const { mutateAsync: autoSave, isPending: isAutoSaving } = useMutation({
      mutationFn: saveWritingApi,
      onSuccess: () => {
        setLastSaved(moment().format("HH:mm:ss"))
      },
    });
    return { autoSave, isAutoSaving ,lastSaved};
  }
  