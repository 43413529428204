import { useState, Fragment } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import { toNumWithComma } from "utils/toNumWithComma";
import { creditword } from "pages/payment/Payment";
import { Check, CloseSquare, CopySuccess } from "iconsax-react";
// plan list


const Pricing = ({ allPlans, setSelectedPlan }) => {
  console.log(allPlans);
  const theme = useTheme();

  const priceListDisable = {
    opacity: 0.4,
    textDecoration: "line-through",
  };

  const priceActivePlan = {
    padding: 3,
    borderRadius: 1,
    bgcolor: theme.palette.primary.lighter,
  };
  const price = {
    fontSize: "40px",
    fontWeight: 700,
    lineHeight: 1,
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
          <Stack spacing={0}>
            <Typography variant="h5">Quality is never an accident. It is always the result of interlligent effort</Typography>
            <Typography color="textSecondary">Select a writing plan to continue.</Typography>
          </Stack>
        </Stack>
      </Grid>      <Grid item container spacing={3} xs={12} alignItems="center">
        {allPlans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan?.id}>
            <MainCard>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    sx={plan?.is_recommended ? priceActivePlan : { padding: 3 }}
                  >
                    <Grid container spacing={3}>
                      {plan?.is_recommended ? (
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                          <Chip label="Popular" color="success" />
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Stack spacing={0} textAlign="center">
                          <Typography variant="h4">
                            {plan?.description}
                          </Typography>
                          <Typography variant="h4" color="GrayText">
                            {creditword(plan?.credit)}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={0} alignItems="end" direction="row" justifyContent="center">
                          <Typography variant="h2" sx={price}>
                            {toNumWithComma(plan?.price)}
                          </Typography>
                          <Typography variant="caption" color="GrayText">
                            Toman
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="success"
                          variant={
                            plan?.is_recommended ? "contained" : "outlined"
                          }
                          fullWidth
                          onClick={() => setSelectedPlan(plan)}
                        >
                          Select Plan
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <List
                    sx={{
                      m: 0,
                      p: 0,
                      "&> li": {
                        px: 0,
                        py: 0.625,
                      },
                    }}
                    component="ul"
                  >
                    {plan?.details != [] &&
                      plan?.details?.map((list, i) => (
                        <Fragment key={i}>
                          <ListItem>
                            {list?.data?.icon == "x" ? (
                              <CloseSquare color="red" />
                            ) : (
                              <CopySuccess color="green" />
                            )}
                            <ListItemText
                              primary={list?.data?.title}
                              sx={{ textAlign: "left",marginLeft:"8px" }}
                            />
                          </ListItem>
                        </Fragment>
                      ))}
                  </List>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Pricing;
