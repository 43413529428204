import { useState } from "react";

// material-ui
import {
  Backdrop,
  Button,
  Divider,
  Fade,
  CardContent,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import WelcomeTimer from "components/exam/IELTSsimulator/WelcomeTimer";
import { useNavigate } from "react-router";

// ==============================|| MODAL - TRANSITIONS ||============================== //

export default function ExitModal({ open, setOpen, save }) {
  function quit() {
    save();
    setOpen(false);
    navigate("/exam");
  }
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.5);",
        },
      }}
    >
      <Fade in={open} sx={{ border: "none", outline: "none" }}>
        <MainCard
          title="Careful!"
          modal
          darkTitle
          content={false}
          sx={{ backgroundColor: "red", outline: "none", border: "none" }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
            >
              <Typography>
                You are about to leave this page,would you like to save your
                writing?
              </Typography>
            </Stack>
          </CardContent>
          <Divider />
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ px: 2.5, py: 2 }}
          >
            <Button
              color="error"
              size="small"
              onClick={() => navigate("/exam")}
            >
              Never mind
            </Button>
            <Button variant="contained" size="small" onClick={quit}>
              Sure!
            </Button>
          </Stack>
        </MainCard>
      </Fade>
    </Modal>
  );
}
