import PropTypes from "prop-types";

// material-ui
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import Avatar from "components/@extended/Avatar";
import { FaChartPie } from "react-icons/fa6";
import { maxHeight } from "@mui/system";

// ==============================|| STATISTICS - REPORT CARD ||============================== //

const ReportCard = ({
  primary,
  secondary,
  iconPrimary,
  color,
  textColor = null,
  description = null,
  variant = 1
}) => {
  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? (
    <IconPrimary size={44} color={textColor} />
  ) : null;

  return (
    <>
    {variant == 2 &&        <MainCard>
     <Grid container justifyContent="space-between" alignItems="center">
       <Grid item sx={{display:'flex',flexDirection:'row',justifyContent:"space-between",alignItems:"center"}}>
         <Stack spacing={0.25}>
           <Typography variant="h3" color={textColor}>
             {primary}
           </Typography>
           <Typography variant="h4" color="text.secondary">
             {secondary}
           </Typography>
         </Stack>
       </Grid>
       <Grid item>{primaryIcon}</Grid>
     </Grid>
   </MainCard>}
    {variant == 1 &&     <MainCard style={{maxHeight:'200px',overflow:'scroll'}}>
    <List disablePadding>
      <ListItem sx={{ p: 0 }}>
        {/* <ListItemAvatar>
          <Avatar color={color} variant="rounded">
            <FaChartPie />
          </Avatar>
        </ListItemAvatar> */}
        <ListItemText
          sx={{ my: 0 }}
          primary={
            <Typography variant="h4" color={textColor} sx={{display:'flex',alignItems:'center',gap:'0.2rem'}}>
                         <Avatar color={color} variant="rounded">
            <FaChartPie />
          </Avatar> {primary}
            </Typography>
          }
          secondary={<Stack gap={2}>
            <Typography variant="subtitle1">{secondary}</Typography>
            {description != null && <Typography variant="body1">{description}</Typography>}
          </Stack>}
        />
      </ListItem>
    </List>
  </MainCard>}
  </>
  );
};

ReportCard.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  iconPrimary: PropTypes.object,
  color: PropTypes.string,
};

export default ReportCard;
