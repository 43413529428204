import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

// project import
import { ThemeMode } from "config";

// chart options
const barChartOptions = {
  chart: {
    type: "bar",
    height: 350,
    animations: {
      enabled: true,
      easing: "linear",
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    y: {
      title: {
        formatter: (seriesName) => "Score"
      }
    }
  },
  xaxis: {
    categories: [
      "Overall",
      "Task Achievement",
      "Coherenece and Cohesion",
      "Lexical Resources",
      "Grammatical Range",
    ],
    max: 9,
  },
  yaxis: {
    max: 9,
  },
};


const ApexBarChart = ({ writing }) => {
  const theme = useTheme();

  const mode = theme.palette.mode;
  const line = theme.palette.divider;
  const { primary } = theme.palette.text;

  const successDark = theme.palette.success.main;

  const [series] = useState([
    {
      data: [
        writing.result["overall_band_score"],
        writing.result["task_achievement"],
        writing.result["coherence"],
        writing.result["lexical"],
        writing.result["grammatical"],
      ],
    },
  ]);

  const [options, setOptions] = useState(barChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: ["#f87171", "#fb923c", "#34d399", "#38bdf8", "#a78bfa"],
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: [primary, primary, primary, primary, primary, primary],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
        },
      },
      grid: {
        borderColor: line,
      },
      theme: {
        mode: mode === ThemeMode.DARK ? "dark" : "light",
      },
    }));
  }, [mode, primary, line, successDark]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ApexBarChart;
