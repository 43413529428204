import { Grid } from "@mui/material";
import React from "react";
import { countwords } from "utils/contwords";
import ReportCard from "../cards/statistics/ReportCard";

function ReportStatsData({ writingResult }) {
  const answerWordCount = countwords(
    writingResult?.task_answer.replaceAll("<br />", "")
  );
  const wordCountColor = answerWordCount < 70 ? "#eab308" : "#22c55e";
  return (
    <Grid container>
      <Grid item xs={6} sm={4}>
        <ReportCard
          primary={answerWordCount}
          textColor={wordCountColor}
          secondary="Total Words"
        />
      </Grid>
    </Grid>
  );
}

export default ReportStatsData;
