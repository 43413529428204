import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import useDashboard from "Api/hooks/useDashboard";
import DashboardImprovements from "components/Improvements/DashboardImprovements";
import DashboardCard from "components/dashboard/DashboardDataCard";
import DashboardTile from "components/dashboard/DashboardTile";
import OverallScoreChart from "components/report/OverallScoreChart";
import { ArrowDown, ArrowUp } from "iconsax-react";
import React, { useState } from "react";
import { FaChartBar, FaChartLine, FaPaperPlane } from "react-icons/fa";
import { GrDocumentPerformance, GrScorecard } from "react-icons/gr";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { Link } from "react-router-dom";
import {
  FcQuestions,
  FcBullish,
  FcDiploma1,
  FcBarChart,
  FcRatings,
  FcSurvey,
} from "react-icons/fc";
const features = [
  {
    title: "Spelling Practice",
    href: "/spelling",
    icon: <FcSurvey fontSize={24} />,
  },
  {
    title: "IELTS Practice",
    href: "/exam/practice",
    icon: <FcQuestions fontSize={24} />,
  },
  {
    title: "IELTS Scoring",
    href: "/exam/scoring",
    icon: <FcRatings fontSize={24} />,
  },
  {
    title: "IELTS Simulator",
    href: "/exam/simulator",
    icon: <FcDiploma1 fontSize={24} />,
  },
  {
    title: "Improvements",
    href: "/improvements",
    icon: <FcBullish fontSize={24} />,
  },
  {
    title: "My Reports",
    href: "/my-reports",
    icon: <FcBarChart fontSize={24} />,
  },
];
function Dashboard() {
  const { dashboard, isLoading } = useDashboard();
  const [showQuickAccess, setShowQuickAccess] = useState(true);
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  console.log(dashboard)
  return (
    <>
      <Stack>
        <Button
          onClick={() => setShowQuickAccess((prev) => !prev)}
          variant="outlined"
          sx={{ maxWidth: "250px",ml:1 }}
        >
          {showQuickAccess
            ? "Hide quick access menu"
            : "Show quick access menu"}
        </Button>
        {showQuickAccess && (
          <Grid container>
            {features.map((feature) => {
              return (
                <Grid item xs={6} md={3} lg={2}>
                  <DashboardTile
                    title={feature.title}
                    href={feature.href}
                    icon={feature.icon}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={1}
      >
        <Typography variant="h5">Exam stats overview</Typography>
        <Button
          component={Link}
          to="/exam"
          variant="contained"
          sx={{ color: "black" }}
        >
          Take an exam
        </Button>
      </Stack>
      <Stack sx={{ marginTop: "1rem",padding:1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <DashboardCard
              color="info"
              title="Total Exams"
              amount={dashboard?.exam_count}
              icon={<HiClipboardDocumentList />}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <DashboardCard
              color="success"
              title="Highest Score"
              amount={Number(dashboard?.max_score).toFixed(1)}
              icon={<ArrowUp style={{ transform: "rotate(45deg)" }} />}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <DashboardCard
              color="warning"
              title="Lowest Score"
              amount={Number(dashboard?.min_score).toFixed(1)}
              icon={<ArrowDown style={{ transform: "rotate(45deg)" }} />}
            />
          </Grid>
          {dashboard.exam_count ? (
            <Grid item xs={12} md={6}>
              <OverallScoreChart scores={dashboard?.overall_scores} />
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <DashboardImprovements improvements={dashboard?.improvements} />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default Dashboard;
