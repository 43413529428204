
// material-ui
import { FormHelperText, Stack, TextField, Typography } from '@mui/material';

// third-party
import { useFormik } from 'formik';
import LoadingButton from 'components/@extended/LoadingButton';


const CartDiscount = ({setCouponCode,handleCoupon,loading,couponCode}) => {

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: couponCode
    },
    onSubmit: (values) => {
      handleCoupon()
    }
  });

  return (
    <Stack justifyContent="flex-end" spacing={1}>
      <Typography align="left" variant="caption" color="textSecondary" sx={{ cursor: 'pointer' }}>
        Have a Discount Code?
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack justifyContent="flex-end" spacing={1}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              id="code"
              name="code"
              fullWidth
              value={formik.values.code}
              placeholder="Enter promo code"
              onChange={(e)=>setCouponCode(e.target.value)}
            />
            
            <LoadingButton loading={loading} type="submit" color="primary" style={{color:'black'}} variant="contained" aria-label="directions">
              Apply
            </LoadingButton>
          </Stack>
          {formik.errors.code && (
            <FormHelperText error id="standard-code">
              {formik.errors.code}
            </FormHelperText>
          )}
        </Stack>
      </form>
    </Stack>
  );
};

export default CartDiscount;
