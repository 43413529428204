import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {useTheme} from '@mui/material/styles'
function OTPCoundown({ resend }) {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const theme = useTheme()
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <div>
      <LoadingButton
      variant="text"
        disabled={seconds > 0 || minutes > 0}
        onClick={(e) => {
          resend()
           setMinutes(1)
            setSeconds(30);
        }}
      >
        {seconds > 0 || minutes > 0 ? (
              <Typography variant="subtitle1" color={theme.palette.primary.main}>
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
        ) : (
            <Typography>Resend Code</Typography>
        )}
      </LoadingButton>
    </div>
  );
}

export default OTPCoundown;
