import { useMutation, useQuery } from "@tanstack/react-query";
import { completeImprovment, getImprovmentsApi } from "Api/providers/examProviders";

export default function useImprovments(){
    const {data:improvments,isLoading,refetch,isRefetching} = useQuery({
        queryKey:['improvments'],
        queryFn:getImprovmentsApi
    })
    const {mutateAsync:complete,isPending:isCompleting} = useMutation({
        mutationFn:completeImprovment,
        onSuccess:()=>{
            refetch()
        }
    })
    return {improvments,isCompleting,isLoading,refetch,complete,isRefetching}
}