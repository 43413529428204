import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT, REGISTER } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project-imports
import Loader from "components/Loader";
import axios from "utils/axios";
import { useNavigate } from "react-router";
const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
const navigate = useNavigate()
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem("serviceToken");
        if (serviceToken) {
          setSession(serviceToken);
          const response = await axios.get("/context");
          const user = {
            name: response?.data?.user?.name,
            email: response?.data?.user?.email,
          };
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post("/login", { email, password });
    const serviceToken = response.data.data.access_token;
    const user = {
      name: response?.data?.data?.name,
      email: response?.data?.data?.email,
    };
    console.log(response)
    if (!response?.data?.data?.email_is_confirmed) {
      navigate("/email-confirmation");
    }
    setSession(serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const register = async (email, password, fullname) => {
    const name = fullname;
    const response = await axios.post("/register", {
      email,
      password,
      name,
    });
    const serviceToken = response.data.data.access_token;
    const user = {
      name: response?.data?.data?.name,
      email: response?.data?.data?.email,
    };
    console.log(response)
    if(!response?.data?.data?.email_is_confirmed){
      navigate('/email-confirmation')
    }
    setSession(serviceToken);
    dispatch({
      type: REGISTER,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    sessionStorage.clear();
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
