import {
  Alert,
  Badge,
  Grid,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import FeaturesBreadcrumbs from "components/FeatureBreadcrumbs";
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";
import {
  ArchiveBook,
  ArrowDown,
  Book,
  Book1,
  Chart1,
  Chart21,
  Global,
  InfoCircle,
  MaximizeCircle,
  TableDocument,
} from "iconsax-react";
import React from "react";
import { LiaInfoCircleSolid } from "react-icons/lia";
import { Outlet, redirect, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useUser from "Api/hooks/useUser";
import { useApp } from "contexts/AppContext";
export const examOptions = [
  {
    name: "exam_type",
    logo: <ArchiveBook size={32} />,
    description: "IELTS academic",
    value: "academic",
  },
  {
    name: "exam_type",
    logo: <Global size={32} />,
    description: "IELTS general",
    value: "general",
  },
];
export const taskOptions = [
  {
    name: "task_type",
    logo: <Chart21 size={32} />,
    description: "Task 1",
    value: 1,
  },
  {
    name: "task_type",
    logo: <Chart1 size={32} />,
    description: "Task 2",
    value: 2,
  },
];
function Exam() {
  const params = useParams();
  const pathname = window.location.pathname;
  console.log(pathname.split("/"));
  return (
    <>
      <FeaturesBreadcrumbs />
      <Outlet />
    </>
  );
}

export default Exam;
export function ExamStepper() {
  const navigate = useNavigate();
  const theme = useTheme();
    return (
    <Stack>
      <ListItemText
        secondary={
          <Typography variant="h4" color={theme.palette.secondary.main}>
            To get started, Please choose one of our services
          </Typography>
        }
        primary={
          <Typography variant="h3">
            We are more than happy to assist you in your journey
          </Typography>
        }
      />
      <Alert color="info" icon={<InfoCircle />}         sx={{ margin: "1rem 0 " }}>
        We'd love to offer everything for free, but producing detailed
        examination reports incurs costs. To maintain our high-quality
        standards, a fee is necessary. Thank you for your understanding and
        support.
      </Alert>
      <Grid
        container
        spacing={3}
        justifyContent="center"
      >
        <Grid item xs={10} sm={4}>
          <EcommerceDataCard
            title="Score my writing"
            color="success"
            iconPrimary={<Book />}
            onClick={() => navigate("scoring")}
          >
            <Typography>
              Submit your task answers and descriptions to receive AI-generated
              instant feedback aligned with official scoring criteria.
              comprehensive analysis addressing key issues and offering
              suggested changes for improvement.
            </Typography>
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={10} sm={4}>
          <EcommerceDataCard
            title="Practice"
            color="warning"
            iconPrimary={<TableDocument />}
            onClick={() => navigate("practice")}
          >
            <Typography>
              Access a wide range of tasks to practice on, sharpening your
              abilities in a focused and structured manner. Elevate your
              proficiency with tailored tasks designed to enhance your expertise
              effectively.
            </Typography>
          </EcommerceDataCard>
        </Grid>
        <Grid item xs={10} sm={4}>
          <EcommerceDataCard
            title="IELTS Simulator"
            color="info"
            onClick={() => navigate("simulator")}
            iconPrimary={<Book1 />}
          >
            <Typography>
              Immerse yourself in a realistic test environment with curated
              tasks to simulate the actual exam conditions. Perfect your
              strategies and approach with this dynamic tool designed to mirror
              the IELTS exam rigorously.
            </Typography>
          </EcommerceDataCard>
        </Grid>
      </Grid>
    </Stack>
  );
}
