import { SelectProvider } from "@mui/base";
import { useQueries, useQuery } from "@tanstack/react-query";
import { spellingTaskApi } from "Api/providers/writingProviders";

export default function useGetSpellingTask() {
  const { data: spellingTask, isLoading } = useQuery({
    queryKey: ["spelling-task"],
    queryFn: spellingTaskApi,
    refetchOnWindowFocus: false,
  });
  return {
    spellingTask,
    isLoading,
  };
}
