import { useMutation } from "@tanstack/react-query";
import { payByCreditApi } from "Api/providers/paymentProviders";
import { useNavigate } from "react-router";
import useUser from "./useUser";

export default function usePayByCredit() {
  const navigate = useNavigate();
  const {refetch} = useUser()
  const { mutateAsync: payByCredit, isPending: isPaying } = useMutation({
    mutationFn: payByCreditApi,
    onSuccess: (res) => {
      refetch()
      if (res.status == "processing") {
        navigate(`/processing/${res.id}`);
      }

    },
  });
  return { payByCredit, isPaying };
}
