import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import MainCard from "../MainCard";
import { ArchiveBook, ArrowLeft, Chart1, Chart21, Global } from "iconsax-react";
import Avatar from "../@extended/Avatar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoLibraryOutline } from "react-icons/io5";
import { IoMdGlobe } from "react-icons/io";
import EcommerceDataCard from "components/cards/statistics/EcommerceDataCard";

export const examOptions = [
  {
    name: "exam_type",
    logo: <IoLibraryOutline />,
    title: "IELTS academic",
    color: "secondary",
    value: "academic",
    description:
      "The IELTS Academic Writing test includes two tasks. Task 1 requires you to describe visual data (charts, graphs, tables, or diagrams) in at least 150 words. Task 2 involves writing a 250-word essay on a complex topic. Focus on clear structure, formal tone, and precise vocabulary to analyze data and construct well-supported arguments.",
  },
  {
    name: "exam_type",
    logo: <IoMdGlobe />,
    title: "IELTS general",
    color: "secondary",
    value: "general",
    description:
      "The IELTS General Writing test also includes two tasks. Task 1 asks you to write a letter (formal, semi-formal, or informal) in at least 150 words. Task 2 requires a 250-word essay on a general topic. Emphasize clear structure, appropriate tone, and practical language skills to effectively communicate your message and support your ideas.",
  },
];
export const taskOptions = [
  {
    name: "task_type",
    logo: <Chart21 size={32} />,
    title: "Task 1",
    color: "secondary",
    value: 1,
    description:
      "ELTS Writing Task 1 requires you to describe visual data (charts, graphs, tables, or diagrams) in at least 150 words. Summarize key information, trends, and comparisons without personal opinions. Focus on clarity, precision, and logical structure with an introduction, overview, and detailed analysis of the data.",
  },
  {
    name: "task_type",
    logo: <Chart1 size={32} />,
    title: "Task 2",
    color: "secondary",
    value: 2,
    description:
      "IELTS Writing Task 2 involves writing a 250-word essay on a given topic. You'll present an argument, discuss a problem, or express an opinion. Ensure a clear introduction, body paragraphs with supporting examples, and a strong conclusion. Coherence, cohesion, and well-developed ideas are crucial for a high score.",
  },
];
function SimulatorOptions({ title1, title2 = "" }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isStep1 = searchParams.has("exam_type");
  const options = isStep1 ? taskOptions : examOptions;
  const title = isStep1 ? title2 : title1;
  const type = isStep1 ? "task_type" : "exam_type";
  const navigate = useNavigate();
  return (
    <Stack spacing={5} alignItems="center">
      <Typography variant="h2" fontWeight={700}>
        {title}
      </Typography>
      <Grid container spacing={1}  maxWidth={800}>
        {options.map((option) => (
          <Grid
            item
            xs={12}
            sm={6}
            // sx={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   margin:"0 1rem"
            // }}
          >
            <EcommerceDataCard
              onClick={() =>
                setSearchParams((prev) => [
                  ...prev.entries(),
                  [type, option.value],
                ])
              }
              title={option.title}
              color="success"
              iconPrimary={option.logo}
              to="scoring"
            >
              <Typography>{option.description}</Typography>
            </EcommerceDataCard>
          </Grid>
        ))}
      </Grid>
      <Button variant="contained" color="error" onClick={() => navigate(-1)}>
        <ArrowLeft />
      </Button>
    </Stack>
  );
}

export default SimulatorOptions;
