import PracticeForm from "components/exam/practice/PracticeForm";
import SimulatorOptions from "components/exam/SimulatorOptions";
import React from "react";
import { useSearchParams } from "react-router-dom";

function Practice() {
  const [searchParams, setSearchParams] = useSearchParams();
  const optionsSelected = searchParams.has("exam_type" && "task_type");
  return (
    <>
      {!optionsSelected && (
        <SimulatorOptions
          title1="Which exam would you like to practice?"
          title2="which would you like to practice?"
        />
      )}
      {optionsSelected && <PracticeForm />}
    </>
  );
}

export default Practice;
