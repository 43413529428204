import { useEffect, useState } from "react";

import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Chip,
  Button,
  CircularProgress,
  Typography,
  Stack,
  ListItemText,
} from "@mui/material";

import MainCard from "components/MainCard";
import useGetExams from "Api/hooks/useGetExams";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import ReportTableHead from "components/report/reportTable/ReportTableHead";
import { getTableStatusAction } from "components/report/reportTable/getTableStatusAction";
import { getStatusColor } from "components/report/reportTable/getTableStatusColor";
import { createReportTableData } from "components/report/reportTable/createReportTableData";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}



export default function ReportsTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedValue, setSelectedValue] = useState([]);
  const { exams, isLoading } = useGetExams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useEffect(() => {
    queryClient.invalidateQueries();
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const rows = exams?.map((exam) =>
    createReportTableData(
      exam?.id,
      exam?.created_at,
      exam?.service_title,
      exam?.exam?.toUpperCase(),
      exam?.type?.toUpperCase(),
      exam?.status?.toUpperCase(),
      "edit"
    )
  );
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedId = rows.map((n) => n.name);
      setSelected(newSelectedId);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    const selectedRowData = rows.filter((row) =>
      newSelected.includes(row.id.toString())
    );
    setSelectedValue(selectedRowData);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <MainCard content={false}>
      {exams.length == 0 && (
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="space-between"
          padding="2rem"
        >
          <ListItemText
            primary={
              <Typography variant="h4">No exam reports were found</Typography>
            }
            secondary={
              <Typography>
                No worries! submit a writing and it will be ready in no time!
              </Typography>
            }
          />
                    <Button
            variant="contained"
            component={Link}
            to="/exam"
            sx={{ color: "black",alignSelf:'flex-end' }}
          >
            Take an exam
          </Button>
        </Stack>
      )}
      {exams.length != 0 && (
        <>
          {" "}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <ReportTableHead
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    if (typeof row === "number") return null;
                    const isItemSelected = isSelected(row?.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    getStatusColor(row?.status);
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row?.date).format("MMMM Do YYYY, h:mm:ss a")}
                        </TableCell>
                        <TableCell align="left">{row?.service}</TableCell>
                        <TableCell align="left">{row?.exam}</TableCell>
                        <TableCell sx={{ pr: 3 }} align="left">
                          {row?.examType}
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            color={getStatusColor(row?.status)}
                            variant="light"
                            label={row?.status}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {getTableStatusAction(row)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow sx={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </MainCard>
  );
}
