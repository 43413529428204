import axios from "../../utils/axios";

export function getExamApi(id) {
  return axios.get(`/exams/${id}`).then(({ data }) => data.data);
}
export function getImprovmentsApi() {
  return axios.get("/improvements").then(({ data }) => data.data);
}
export function completeImprovment(id) {
  return axios.post(`/improvements/${id}/done`).then(({ data }) => data.data);
}
