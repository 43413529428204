// material-ui
import { Stack, Typography } from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import ApexLineChart from "sections/charts/apexchart/ApexLineChart";

// assets

// ==============================|| CHART - REPEAT CUSTOMER RATE ||============================== //

const OverallScoreChart = ({ scores }) => {
  return (
    <MainCard>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography variant="h5">Overall score</Typography>
      </Stack>
      <ApexLineChart scores={scores} />
    </MainCard>
  );
};

export default OverallScoreChart;
