import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../utils/axios";
import {
  Alert,
  CardMedia,
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import generating from "../../assets/images/waiting.png";
import { QuoteDown } from "iconsax-react";
const quotes = [
  "As you take a moment to enjoy a peaceful meditation, we're meticulously crafting your report. Stay zen, we'll notify you when it's ready to enlighten you.",
  "While you're finding your inner calm, we're aligning the stars of data to bring you an insightful report. Breathe easy, we'll let you know the moment it's ready.",
  "In the next few minutes, as you embrace a moment of mindfulness, we're busy weaving wisdom into your report. Relax and be present; we'll inform you when it's prepared.",
  "Imagine your data as a serene garden. We're tending to each detail with care. Once it's in full bloom, we'll send you a notification to come and see.",
  "Your report is on a gentle journey through the realms of analysis. Feel free to stretch or take a mindful walk. We’ll alert you when your insights have arrived.",
  "Think of this as a short wellness break for your brain while we assemble your report. Once it's packed with insights, we'll reach out to let you know.",
  "While you're catching a quick moment of serenity, we're infusing your report with clarity and precision. You'll be notified as soon as it's ready to inspire.",
  "Consider this a brief interlude of tranquility in your day as we polish your report. We'll ring the bell (or send a notification) when it's time to view.",
  "As you indulge in a few minutes of peaceful reflection, we're ensuring your report is equally thoughtful. We’ll give you a heads-up the instant it's ready.",
  "Use this waiting time to embrace a short mindful pause. Your report is blossoming with insights and will be with you soon, complete with a notification to guide you there.",
  "We're on a treasure hunt in the vast ocean of data, diving deep to fetch pearls of information for your report. Don't wander off too far; we'll send a message in a bottle to your shore once we've surfaced with your treasure!",
  "Think of this as a coffee break for your brain while our digital baristas whip up your report. It's brewing with care and precision. We'll ring the café bell (aka notify you) when your order is ready to be savored.",
  "Our team of data elves is busy crafting your report in our workshop. Just like Santa’s helpers, they're meticulous and a bit magical. We'll slide down your digital chimney with a notification as soon as it's wrapped up and ready!",
  "Imagine we're planting a garden of data, and your report is the most beautiful flower. It's currently budding and about to bloom. We'll send a gentle breeze (in the form of a notification) to let you know when it's in full bloom.",
  "We've sent a team of data detectives on a mission to compile your report. They're following the clues meticulously and will crack the case soon. You'll receive a top-secret notification when your dossier is ready for review.",
  "Your report is being painted by our team of digital artists, each stroke of data adding to the masterpiece. Like any fine art, it takes time. We'll frame it and hang it in your notification gallery once it's a pièce de résistance.",
  "Think of this as a short intermission at the theater of data. Your report is the main act, and it's worth the wait. We'll flash the lights and open the curtains (send a notification) when the show is ready to go on.",
  "Your report is on a leisurely train journey through our system, taking in the scenic route of data analysis. We'll send a telegram to your station once it arrives at its destination, all set and ready for you to review.",
  "We're assembling your report like a complex jigsaw puzzle, making sure every piece of data fits perfectly. Puzzle-solving takes time, but the picture is worth it. We'll notify you when this masterpiece is complete and ready for viewing.",
  "Our digital chefs are seasoning your report with just the right amount of data spices. It's in the oven, baking to perfection. We'll ring the kitchen timer (aka send a notification) when it's ready to be served hot and fresh.",
];

const quote = quotes[Math.floor(Math.random() * quotes.length)];

function Processing() {
  const [writing, setWriting] = useState({});
  const navigate = useNavigate();
  let refetchIntervalRef = useRef(null);
  useEffect(() => {
    loadWriting();
    return () => {
      clearTimeout(refetchIntervalRef.current)
    };
  }, []);

  const loadWriting = () => {
    axios.get("/exams/" + id).then((resp) => {
      setWriting(resp.data.data);
      if (resp.data.data.status !== "done") {
        refetchIntervalRef.current = setTimeout(() => {
          loadWriting();
        }, 5000);
      } else {
        navigate("/result/" + resp.data.data.id);
      }
    });
  };

  const { id } = useParams();
  console.log(id);
  return (
    <Stack spacing={3} alignItems="center">
      <Typography variant="h3" sx={{ textAlign: "center" }} fontWeight={700}>
        WE ARE GENERATING YOUR WRITING REPORT
      </Typography>
      <CardMedia
        component="img"
        image={generating}
        sx={{ width: "200px", margin: "1rem auto" }}
      />
      <Alert icon={<QuoteDown />} sx={{ fontSize: "15px" }}>
        {quote}
      </Alert>
      <CircularProgress />
      <Typography sx={{ textAlign: "center" }}>
        You can wait here or just close this page and we'll notify you when it's
        ready
      </Typography>
    </Stack>
  );
}

export default Processing;
