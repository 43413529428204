// material-ui
import { Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

// material-ui

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: "24px 16px 0px", mt: "auto" }}
  >
    <Typography variant="caption">
      WritingChex Copyright © 2024
    </Typography>
    <Stack
      spacing={1.5}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Link
        component={RouterLink}
        to="https://writingchex.com/contact"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Contact us
      </Link>
      <Link
        component={RouterLink}
        to="https://writingchex.com/privacy"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Privacy Policy
      </Link>
      <Link
        component={RouterLink}
        to="https://writingchex.com/terms"
        target="_blank"
        variant="caption"
        color="textPrimary"
      >
        Terms & Conditions
      </Link>
    </Stack>
  </Stack>
);

export default Footer;
