// project-imports
import support, { guest } from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [support]
};
export const guestItems = {
  items:[guest]
}
export default menuItems;
