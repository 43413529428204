import { useQuery } from "@tanstack/react-query";
import { writingListApi } from "Api/providers/writingProviders";

export default function useGetWritings() {
  const { data: writings, isLoading: writingLoading ,refetch} = useQuery({
    queryKey: ["writings-list"],
    queryFn: writingListApi,
  });
  return { writings, writingLoading,refetch };
}
