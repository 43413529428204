export   function getStatusColor(status) {
    switch (status.toLowerCase()) {
      case "draft": {
        return "warning";
      }
      case "waiting": {
        return "info";
      }
      case "done": {
        return "success";
      }
      case "error": {
        return "default";
      }
      default: {
        return "error";
      }
    }
  }