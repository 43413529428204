import React, { useState } from "react";
import MainCard from "./MainCard";
import { Button, Stack, Typography } from "@mui/material";
import email from "../assets/images/email.png";
import useUser from "Api/hooks/useUser";
import OTPInput from "./OTPInput";
import { Modal } from "@mui/base";
import { LoadingButton } from "@mui/lab";
import OTPCoundown from "./OTPCountdown";
function ConfirmEmailBox({verify,resend,isVerifying,isResending}) {
  const user = useUser();
  const [code, setCode] = useState("");
  return (
          <MainCard
            sx={{
              width: { xs: "360px", sm: "450px", md: "650px" },
              border: "none",
            }}
            
          >
            <Stack alignItems="center">
              <img src={email} style={{ width: "150px", height: "150px" }} />
              <Typography variant="h4">Confirm your email address</Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  marginTop: "1rem",
                  textAlign: "center",
                  maxWidth: "500px",
                }}
              >
                confirm your email address and recieve a free writing! We sent
                verification code to{" "}
                <span style={{ color: "#22c55e" }}>
                  {user?.user?.user?.email}
                </span>
                &nbsp; Please check your inbox and enter the code below. you
                will be granted a free writing after the verification has been
                completed.
              </Typography>
              <Stack sx={{ marginTop: "1.5rem" }} gap={2}>
                <Typography sx={{ textAlign: "center"}}>
                  Enter your 6-digits code
                </Typography>
                <OTPInput code={code} setCode={setCode} />
                <LoadingButton variant="contained" loading={isVerifying} onClick={()=>verify(code)}>Verify</LoadingButton>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ marginTop: "1rem" }}
                gap={1}
              >
                <Typography>Didn&apos;t receive the code?</Typography>
                {/* <Button variant="text">Resend Code</Button> */}
                {/* <LoadingButton loading={isResending} onClick={resend}>Resend Code</LoadingButton> */}
                <OTPCoundown resend={resend}/>
              </Stack>
            </Stack>
          </MainCard>
  );
}

export default ConfirmEmailBox;
