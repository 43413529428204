import { ListItemText, Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import { MinusCirlce, TickCircle } from "iconsax-react";
import React from "react";
import { PiArrowsCounterClockwiseFill } from "react-icons/pi";
import { VscWholeWord } from "react-icons/vsc";
function FrequentlyUsedWords({ words }) {
  return (
    <MainCard titleVariant="h4" title="Frequently used words">
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        maxHeight={300}
        minHeight={200}
        overflow="scroll"
        justifyContent="center"
      >
        {words.map((word) => (
          <div>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                padding: "0",
                gap: "4px",
              }}
            >
              <VscWholeWord fontSize={18} color="green" />
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                {word.word}
              </p>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                margin: 0,
                padding: "0",
                gap: "4px",
              }}
            >
              <PiArrowsCounterClockwiseFill fontSize={18} color="#3b82f6" />
              <p style={{ margin: 0, padding: 0 }}>
                {word.correct}&nbsp;
                {word.correct > 1 ? "times" : "time"}
              </p>
            </span>
          </div>
        ))}
      </Stack>
    </MainCard>
  );
}

export default FrequentlyUsedWords;
