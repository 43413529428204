import SimulatorOptions from "components/exam/SimulatorOptions";
import React from "react";
import { examOptions, taskOptions } from "./Exam";
import { useSearchParams } from "react-router-dom";
import { optionClasses } from "@mui/base";
import { Stack } from "@mui/material";
import { useGetTask } from "Api/hooks/useGetTask";
import ScoringForm from "components/exam/scoreMyWriting/ScoringForm";

function Scoring() {
  const [searchParams, setSearchParams] = useSearchParams();
  const optionsSelected = searchParams.has("exam_type" && "task_type");
  const isEdit = searchParams.has("examId");
  return (
    <>
      {!isEdit && !optionsSelected && (
        <SimulatorOptions
          title1="What type is your writing?"
          title2="What task is your writing?"
        />
      )}
      {optionsSelected && <ScoringForm />}
      {isEdit && <ScoringForm />}
    </>
  );
}

export default Scoring;
