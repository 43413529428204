import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

// project import
import { ThemeMode } from "config";
import moment from "moment";

// chart options
const lineChartOptions = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  },
};

// ==============================|| APEXCHART - LINE ||============================== //

const ApexLineChart = ({ scores }) => {
  const categories = scores?.map((score) =>
    moment(score?.date).format("MMM Do YYYY")
  );
  const seriesScores = scores?.map((score) => Number(score?.score).toFixed(1));
  const theme = useTheme();
  const mode = theme.palette.mode;

  const line = theme.palette.divider;
  const { primary } = theme.palette.text;
  const grey200 = theme.palette.secondary[200];
  const secondary = theme.palette.primary[700];

  const [series] = useState([
    {
      name: "Overall score",
      data: seriesScores,
    },
  ]);

  const [options, setOptions] = useState(lineChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [secondary],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: "#fff",
        },
        offsetY: -7, // adjust the vertical position of the label
      },
      markers: {
        size: 6,
        strokeWidth: 2,
        hover: {
          size: 9,
        },
      },
      xaxis: {
        categories,
        labels: {
          style: {
            colors: [
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
              primary,
            ],
          },
        },
      },
      yaxis: {
        max: 9,
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      grid: {
        borderColor: line,
      },
      theme: {
        mode: mode === ThemeMode.DARK ? "dark" : "light",
      },
    }));
  }, [mode, primary, line, grey200, secondary]);
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default ApexLineChart;
