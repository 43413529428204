import { Button, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import { InfoCircle } from "iconsax-react";
import { Link } from "react-router-dom";

function NoActiveImprovements() {
    return (
      <MainCard sx={{ marginTop: "1rem" }}>
        <Stack>
          <Stack>
            <ListItem>
              <ListItemAvatar>
                <Avatar color="info">
                  <InfoCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle1">
                    IELTS Genuis! There are no Active study plans / improvements
                    found at the moment.
                  </Typography>
                }
                secondary={
                  <Typography>
                    Submit a new exam to have access your personalized study
                    plans, if there are any!
                  </Typography>
                }
              />
            </ListItem>
          </Stack>
          <Button
            variant="contained"
            sx={{ alignSelf: "flex-end", color: "black" }}
            component={Link}
            to="/exam"
          >
            Take an Exam
          </Button>
        </Stack>
      </MainCard>
    );
  }
  export default NoActiveImprovements