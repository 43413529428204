import { Typography } from "@mui/material";
import { useState,useEffect } from "react";
export default function WelcomeTimer({ startExam }) {
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
  
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [seconds]);
    if (seconds === 0 && minutes === 0) {
      startExam();
    }
    return (
        <Typography variant="h4">
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
    );
  }