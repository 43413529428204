import {
  Alert,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import useGetResult from "Api/hooks/useGetResult";
import useUser from "Api/hooks/useUser";
import axios from "../../utils/axios";
import MainCard from "components/MainCard";
import ReportHeaderData from "components/report/ReportHeader";
import ReportSection from "components/report/ReportSection";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ApexBarChart from "sections/charts/apexchart/ApexBarChart";
import IconButton from "themes/overrides/IconButton";
import { diffWords } from "diff";
import "../main.css";
import useConfig from "hooks/useConfig";
import ReportCard from "components/cards/statistics/ReportCard";
import { countwords } from "utils/contwords";
import ReportStatsData from "components/report/ReportStatsData";
import { InfoCircle } from "iconsax-react";
const titles = {
  task_achievement: "Task Achievement",
  coherence: "Coherence and Cohesion",
  lexical: "Lexical Resources",
  grammatical: "Grammatical Range and  Accuracy",
};
function Report() {
  const { user, refetch: refreshUser } = useUser();
  const { id } = useParams();
  const [writingResult, setWritingResult] = useState([]);
  const [improvedWriting, setImprovedWriting] = useState();
  const [showChanges, setShowChanges] = useState(false);
  const { result } = writingResult || [];
  const { exam } = writingResult || [];
  const [isResultLoading, setIsResultLoading] = useState(true);
  const isExample = id == "example";
  console.log(writingResult)
  useEffect(() => {
    axios
      .get(isExample ? "example-report" : `/writings/${id}`)
      .then(({ data }) => {
        setWritingResult(data.data);
        setIsResultLoading(false);
        const improved_writing = data.data.result["improved_writing"]
          .replaceAll("<br>", "\n")
          .replaceAll("<br/>", "\n")
          .replaceAll("/>", "\n");
        const task_answer = data.data.task_answer.replaceAll("<br />", "\n");
        const changes = diffWords(task_answer, improved_writing, {
          ignoreCase: true,
        });
        let text = "";
        changes.forEach((c) => {
          if (c.added) {
            text += '<span class="added">' + c.value + "</span>";
          }
          if (c.removed) {
            text +=
              '<span class="removed">' +
              c.value.replaceAll("\n", "") +
              "</span>";
          }
          if (!c.added && !c.removed) {
            text += "<span>" + c.value + "</span>";
          }
        });
        setImprovedWriting(text.replaceAll("\n", "<br/>"));
      })
      .catch((error) => setIsResultLoading(false));
  }, []);
  if (isResultLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Stack spacing={4} >
      <Typography variant="h2" fontWeight={700} sx={{ marginBottom: "1rem" }}>
        Your writing report
      </Typography>
      {isExample && (
        <Alert icon={<InfoCircle />}>
          This is a sample report, by getting a writing package, you will have
          access to all of our features.{" "}
        </Alert>
      )}
      <ReportHeaderData user={user} exam={exam} writingResult={writingResult} />
      <ReportStatsData writingResult={writingResult} />
      <MainCard title="Preformance summary" titleVariant="h4">
        <Typography sx={{ marginBottom: "2rem" }}>
          {result["comment"]}
        </Typography>
        <ApexBarChart writing={writingResult} />
      </MainCard>
      {Object.keys(titles).map((key, index) => (
        <>
          <ReportSection
            key={key}
            writing={writingResult}
            result_key={key}
            title={titles[key]}
          />
        </>
      ))}
    </Stack>
  );
}

export default Report;
