import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import { Document } from "iconsax-react";
import React from "react";
import IELTSTimer from "./IELTSsimulator/IELTSTimer";
import ExamServicesTimer from "./ExamServicesTimer";

function ExamServicesHeader({ currentTask, setTaskDuration,isPaused,setIsPaused }) {
  return (
    <MainCard sx={{ marginBottom: "1rem" }}>
      <Stack
        direction={{ md: "row", sm: "column" }}
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
        spacing={5}
      >
        <List disablePadding>
          <ListItem sx={{ p: 0 }}>
            <ListItemAvatar>
              <Avatar color="info" variant="rounded">
                <Document variant="Bold" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ my: 0 }}
              primary={
                <Typography variant="subtitle1">Task {currentTask}</Typography>
              }
              secondary={
                <Typography variant="subtitle2">
                  You should spend about {currentTask == 1 ? "20" : "40"}
                  &nbsp; minutes on this task. Write at least 50 words.
                </Typography>
              }
            />
          </ListItem>
        </List>
        <ExamServicesTimer
        isPaused={isPaused}
        setIsPaused={setIsPaused}
          setTaskDuration={setTaskDuration}
          initialTime={currentTask == 1 ? 1200 : 2400}
        />
      </Stack>
    </MainCard>
  );
}

export default ExamServicesHeader;
