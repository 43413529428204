import SimulatorForm from "components/exam/IELTSsimulator/SimulatorForm";
import SimulatorOptions from "components/exam/SimulatorOptions";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

function Simulator() {
  const [searchParams, setSearchParams] = useSearchParams();
  const optionsSelected = searchParams.has("exam_type");
  return (
    <>
      {!optionsSelected && (
        <SimulatorOptions title1="Do you want your exam to be academic or general?" />
      )}
      {optionsSelected && <SimulatorForm />}
    </>
  );
}

export default Simulator;
