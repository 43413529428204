import {
  Button,
  Divider,
  Grid,
  List,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import { diffWords } from "diff";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "../main.css";
import ReportCard from "components/cards/statistics/ReportCard";
import { countwords } from "utils/contwords";
import { LuTimer } from "react-icons/lu";
import {
  LiaCheckCircle,
  LiaInfoCircleSolid,
  LiaTimesCircle,
} from "react-icons/lia";
import useConfig from "hooks/useConfig";
function SpellingResult() {
  const location = useLocation();
  const writingData = location.state;
  const [diffWriting, setDiffWriting] = useState();
  const [mistakes, setMistakes] = useState([]);
  const [corrections, setCorrections] = useState();
  const [forgottenWords, setForgottenWords] = useState([]);
  const navigate = useNavigate();
  const { mode } = useConfig();
  const mistakesColor = mistakes.length
    ? mistakes.length > 10
      ? "red"
      : mistakes.length > 5
      ? "orange"
      : "green"
    : "green";
  const wpm = (countwords(writingData.task_answer) * 60) / writingData.time;
  const wpmColor = wpm > 60 ? "green" : wpm > 30 ? "orange" : "red";
  const changes = diffWords(
    writingData.task_description,
    writingData.task_answer,
    { ignoreCase: true, ignoreWhitespace: true }
  );
  useEffect(() => {
    setDiffWriting(changes);
    const newMistakes = [];
    const newCorrections = [];
    const newForgottenWords = [];

    changes.forEach((part) => {
      if (part.added) {
        if (/\S/.test(part.value)) {
          newMistakes.push(part.value.toLowerCase());
        }
      } else if (part.removed) {
        if (/\S/.test(part.value)) {
          newForgottenWords.push(part.value.toLowerCase());
        }
      } else if (part.removed && part.added) {
        if (/\S/.test(part.value)) {
          newCorrections.push(part.value.toLowerCase());
        }
      }
    });

    setMistakes(newMistakes);
    setForgottenWords((prevForgottenWords) => [
      ...prevForgottenWords,
      ...newForgottenWords,
    ]);
    setCorrections(newCorrections);
  }, [writingData]);
  console.log(changes, forgottenWords);
  function tokenizeAndClean(text) {
    return text
      .toLowerCase()
      .split(/[ ,.]+/) 
      .filter(word => word.trim() !== "");
  }

  function calculateEditDistance(s1, s2) {
    const dp = Array(s2.length + 1)
      .fill(null)
      .map(() => Array(s1.length + 1).fill(null));

    for (let i = 0; i <= s1.length; i++) dp[0][i] = i;
    for (let j = 0; j <= s2.length; j++) dp[j][0] = j;

    for (let j = 1; j <= s2.length; j++) {
      for (let i = 1; i <= s1.length; i++) {
        const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
        dp[j][i] = Math.min(
          dp[j - 1][i] + 1, // deletion
          dp[j][i - 1] + 1, // insertion
          dp[j - 1][i - 1] + cost // substitution
        );
      }
    }
    return dp[s2.length][s1.length];
  }

  function analyzeText(originalText, userText) {
    const originalWords = tokenizeAndClean(originalText);
    const userWords = tokenizeAndClean(userText);
    const matchMap = new Map();
    const results = {
      forgottenWords: [],
      misspelledWords: [],
      correctWords: [],
    };

    userWords.forEach((word) => {
      matchMap.set(word, (matchMap.get(word) || 0) + 1);
    });

    const editThreshold = 2; //Adjusted threshold for better matching

    // First pass: Match directly or via close misspelling
    originalWords.forEach((word) => {
      if (matchMap.has(word) && matchMap.get(word) > 0) {
        results.correctWords.push(word);
        matchMap.set(word, matchMap.get(word) - 1);
      } else {
        let matched = false;
        for (let [candidate, count] of matchMap) {
          if (
            count > 0 &&
            calculateEditDistance(word, candidate) <= word.length / 2
          ) {
            results.misspelledWords.push({
              correct: word,
              misspelled: candidate,
            });
            matchMap.set(candidate, matchMap.get(candidate) - 1);
            matched = true;
            break;
          }
        }
        if (!matched) {
          results.forgottenWords.push(word);
        }
      }
    });

    return results;
  }

  // Example usage
  const result = analyzeText(
    writingData.task_description,
    writingData?.task_answer
  );


  return (
    <Stack>
      <Typography variant="h2" fontWeight={700} marginBottom={2}>
        Spelling Result
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <ReportCard
            textColor={wpmColor}
            primary={wpm.toFixed(0)}
            secondary="words per minute"
            iconPrimary={LuTimer}variant={2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReportCard
            primary={mistakes.length}
            textColor={mistakesColor}
            iconPrimary={LiaTimesCircle}
            secondary="Total Mistakes"
            variant={2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MainCard title="Writing">
            <Typography
              className={`highlight active ${mode == "dark" ? "dark" : ""}`}
              sx={{
                lineHeight: "30px",
                fontSize: "18px",
                maxHeight: "400px",
                overflow: "auto",
              }}
            >
              {diffWriting?.map((part, index) => (
                <span
                  key={index}
                  className={
                    part.added ? "extra" : part.removed ? "wrong" : "match"
                  }
                >
                  {part.value}
                </span>
              ))}
            </Typography>
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MainCard title="Misspells">
            {!mistakes.length && !result.forgottenWords.length && (
              <Typography variant="h3">
                <LiaCheckCircle color="green" style={{ margin: "0 1rem" }} />
                Congrats! You dont have any spelling mistakes.
              </Typography>
            )}
            {mistakes.length || result.forgottenWords.length ? (
              <Stack sx={{ maxHeight: "400px", overflow: "auto" }} spacing={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1">misspell</Typography>
                  <Typography variant="subtitle1">correction</Typography>
                </Stack>
                {result.misspelledWords.map((word) => {
                  return (
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          aligItems: "center",
                          display: "flex",
                          gap: "0.5rem",
                        }}
                      >
                        <LiaTimesCircle
                          color="red"
                          style={{ fontSize: "18px" }}
                        />
                        {word.misspelled}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          aligItems: "center",
                          display: "flex",
                          gap: "0.5rem",
                        }}
                      >
                        <LiaCheckCircle color="green" fontSize="18px" />
                        {word.correct}
                      </Typography>
                    </Stack>
                  );
                })}
                <Divider />
                <Typography variant="subtitle1">Forgotten Words</Typography>
                <Stack>
                  {result.forgottenWords.map((word) => {
                    return (
                      <Typography
                        variant="h5"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <LiaInfoCircleSolid fontSize="18px" color="orange" />
                        {word}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
            ) : null}
          </MainCard>
          <MainCard sx={{ marginTop: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <Button onClick={() => navigate("/spelling")} variant="contained">
                New Practice
              </Button>
              <Button
                onClick={() => navigate("/")}
                variant="outlined"
                color="error"
              >
                Quit
              </Button>
            </Stack>
          </MainCard>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default SpellingResult;
