import React, { useCallback, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import axios from "utils/axios";
import useStripePayment from "hooks/useStripePayment";
import useUser from "Api/hooks/useUser";
const stripePromise = loadStripe(
  "pk_live_51P8zIrP4RRD7T0lICSt4MDEfhLB4lrmo7n0uSjSCJbnh7jRkZc2C4xBoi3rB4Rd0auNpESG79AZrgH9BnTmEcbWj00ytnXhMs0"
);
export function CheckoutForm({ paymentData }) {
  const fetchClientSecret = useCallback(() => {
    return axios
      .post("/checkout", paymentData)
      .then((res) => res.data.data.client_secret);
  }, []);
  const options = { fetchClientSecret };
  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
export const StripeResultReturn = () => {
  const { getPaymentStatus, finalizePayment, status } = useStripePayment();
  const {refetch} = useUser()
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get("session_id");
  useEffect(() => {
    getPaymentStatus(sessionId);
    refetch()
  }, []);
  return <div>{finalizePayment(status, sessionId)}</div>;
};
