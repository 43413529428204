import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import UserPersonalData from "components/report/ReportSummary";
import EcommerceRadial from "components/report/ScoreChart";
import useUser from "Api/hooks/useUser";
import Avatar from "components/@extended/Avatar";
import { Calendar, Document, User } from "iconsax-react";
import moment from "moment";
import axios from "../../utils/axios";
import ReportSection from "components/report/ReportSection";
import ReportHeaderData from "components/report/ReportHeader";
import ReportCard from "components/cards/statistics/ReportCard";
import FrequentlyUsedWords from "./FrequentlyUsedWords";
import GrammerErrors from "./GrammerErrors";
import SpellingErrors from "./SpellingErrors";
import { useSearchParams } from "react-router-dom";
const titles = {
  task_achievement: "Task Achievement",
  coherence: "Coherence and Cohesion",
  lexical: "Lexical Resources",
  grammatical: "Grammatical Range and  Accuracy",
};
function Print() {
  const [searchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const writingId = params.get("writing_id");
  const secret = params.get("secret");
  const [writing, setWriting] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState(2);
  const [exam, setExam] = useState([]);
  useEffect(() => {
    axios
      .get(`/writing/${writingId}?secret=${secret}`)
      .then(({ data }) => {
        setWriting(data?.data);
        setExam(data?.data?.exam);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  // useEffect(() => {
  //   const writing = exam?.writings?.find(
  //     (writing) => Number(writing?.task_type) == selectedTask
  //   );
  //   setwriting(writing);
  // }, [selectedTask]);
  function getStatReportColor(score) {
    const color = score > 4 ? "#4ade80" : "#f43f5e";
    return color;
  }
  // const { user, isLoading: userLoading } = useUser();
  const color = exam?.score > 4 ? "#4ade80" : "#f43f5e";
  console.log(writing);
  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Stack spacing={3} sx={{ padding: "2rem" }}>
      <Typography variant="h2" sx={{ fontWeight: 700 }}>
        Writing Result
      </Typography>
      <Stack>
        <Grid container rowSpacing={1.5} columnSpacing={2.75}>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard style={{ maxHeight: "95px" }}>
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar color="info" variant="rounded">
                      <User variant="Bold" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={<Typography>{writing?.user_name}</Typography>}
                    secondary={
                      <Typography variant="subtitle1">
                        {exam?.exam?.toUpperCase()}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard style={{ maxHeight: "95px" }}>
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar color="warning" variant="rounded">
                      <Document variant="Bold" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={<Typography>{exam?.service.split("_").join("")}</Typography>}
                    secondary={
                      <Typography variant="subtitle1">
                        {exam?.type?.toUpperCase()}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard style={{ maxHeight: "95px" }}>
              <List disablePadding>
                <ListItem sx={{ p: 0 }}>
                  <ListItemAvatar>
                    <Avatar color="success" variant="rounded">
                      <Calendar variant="Bold" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={<Typography>Submitted At</Typography>}
                    secondary={
                      <Typography variant="subtitle1">
                        {moment(exam?.created_at).format("MMMM Do YYYY")}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MainCard
              content={false}
              style={{ maxHeight: "95px", display: "flex" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ px: 2, marginBottom: "1rem" }}
              >
                <Stack sx={{ width: 120 }}>
                  <EcommerceRadial
                    color={color}
                    value={Number(exam?.score)?.toFixed(1)}
                  />
                </Stack>
                <Stack>
                  <Typography>Overall Score</Typography>
                  <Typography variant="subtitle1">
                    {Number(exam?.score)?.toFixed(1)} out of 9.0
                  </Typography>
                </Stack>
              </Stack>
            </MainCard>
          </Grid>
        </Grid>
      </Stack>
      <Stack>
        <Grid
          container
          rowSpacing={1.5}
          columnSpacing={2.75}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <div className="print-break-after">
              <ReportHeaderData
                print
                exam={exam}
                writingResult={writing}
                selectedTask={selectedTask}
                examWritings={exam?.writings}
                setSelectedTask={setSelectedTask}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="print-break-after">
              <UserPersonalData
                selectedTask={selectedTask}
                writing={writing}
                examWritings={exam?.writings}
                setSelectedTask={setSelectedTask}
              />
            </div>
          </Grid>
          {writing.key && writing.key == "v2" ? (
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${writing?.result?.clarity_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(writing?.result?.clarity_score)}
                  secondary="Clarity Score"
                  iconColor="primary"
                  description="This measures how clearly and effectively your ideas are communicated. A high clarity score indicates that your writing is easy to understand and free from ambiguity."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${writing?.result?.example_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(writing?.result?.example_score)}
                  secondary="Example Score"
                  iconColor="info"
                  description="This assesses the relevance and quality of examples you use to support your arguments. A high example score means your examples are pertinent, well-explained, and enhance your points."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${writing?.result?.readability_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(
                    writing?.result?.readability_score
                  )}
                  secondary="Readability Score"
                  iconColor="success"
                  description="This evaluates how easily your text can be read and understood. Factors influencing readability include sentence length, word choice, and overall flow. A high readability score means your writing is smooth and accessible."
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <ReportCard
                  primary={`${writing?.result?.repetition_score?.toFixed(
                    1
                  )} out of 9.0`}
                  textColor={getStatReportColor(
                    writing?.result?.repetition_score
                  )}
                  secondary="Repetitaion Score"
                  iconColor="secondary"
                  description="This measures the degree of unnecessary repetition in your writing. A low repetition score indicates that your writing is concise, varied, and free from redundant phrases or ideas.
                     "
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FrequentlyUsedWords
                  words={writing?.result?.frequently_words}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <GrammerErrors
                  grammerErrors={writing?.result?.grammer_errors}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SpellingErrors
                  spellingErrors={writing?.result?.spelling_errors}
                />
              </Grid>
            </Grid>
          ) : null}
          {Object.keys(titles).map((key, index) => (
            <>
              <Grid item xs={12}>
                <div className="print-break-after">
                  <ReportSection
                    print
                    key={key}
                    writing={writing}
                    result_key={key}
                    title={titles[key]}
                  />
                </div>
              </Grid>
            </>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}

export default Print;
