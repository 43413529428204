import { useState } from "react";

// material-ui
import {
  Backdrop,
  Button,
  Divider,
  Fade,
  CardContent,
  Modal,
  Stack,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import WelcomeTimer from "components/exam/IELTSsimulator/WelcomeTimer";
import { useNavigate } from "react-router";

// ==============================|| MODAL - TRANSITIONS ||============================== //

export default function TransitionsModal({ open, setOpen, startExam }) {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.5);",
        },
      }}
    >
      <Fade in={open} sx={{ border: "none", outline: "none" }}>
        <MainCard
          title="IELTS Simulator"
          modal
          darkTitle
          content={false}
          sx={{ backgroundColor: "red", outline: "none", border: "none" }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              spacing={4}
            >
              <Typography variant="h5" id="modal-modal-description">
                Your exam will automatically start in
              </Typography>
              <WelcomeTimer startExam={startExam} />
            </Stack>
          </CardContent>
          <Divider />
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ px: 2.5, py: 2 }}
          >
            <Button color="error" size="small" onClick={() => navigate(-1)}>
              Quit
            </Button>
            <Button variant="contained" size="small" onClick={startExam}>
              Start now
            </Button>
          </Stack>
        </MainCard>
      </Fade>
    </Modal>
  );
}
