import { useMutation } from "@tanstack/react-query";
import { saveWritingApi } from "Api/providers/writingProviders";
import { useNavigate } from "react-router";

export function useSaveWriting() {
    const navigate = useNavigate()
    const {
      mutateAsync: saveWriting,
      isPending: isSaving,
      error,
    } = useMutation({
      mutationFn: saveWritingApi,
      onSuccess: (data) => {
          navigate('/drafts')
      },
    });
    return { saveWriting, isSaving };
  }
  